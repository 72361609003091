import BaseModel from "./BaseModel";
import {
    // Enrollment,
    // Genome,
    // Response,
    // Order,
    // Kit,
    // Condition,
    // Diagnosis,
    // Study,
    // Eligibility,
    // Site,
    // SiteUser,
    // Partner,
    Role,
    UserRole,
} from "../models";

export default class User extends BaseModel {
    static entity = "users";

    static fields() {
        return {
            // attributes
            id: this.attr(null),
            created_at: this.attr(""),
            updated_at: this.attr(""),
            email: this.attr(""),
            notifications: this.attr(""),
            monthly_newsletter: this.attr(""),
            // legal full name (used in cases like consent and studies)
            fullname: this.attr(""),
            // site username (used for getting in contact with users)
            name: this.attr(""),
            inform_partner: this.attr(""),
            utm_source: this.attr(""),
            utm_medium: this.attr(""),
            utm_campaign: this.attr(""),
            utm_content: this.attr(""),
            registration_source: this.attr(""),
            referral_code: this.attr(""),
            privacy_services: this.attr(""),
            represented_by: this.attr(""),
            phone: this.attr(""),
            contactable_by_phone: this.attr(""),
            shipping_address: this.attr(""),
            cohort: this.attr(""),
            created_by: this.attr(""),
            password_set: this.attr(""),
            email_validated: this.attr(""),
            birthday: this.attr(""),
            nhs_number: this.attr(""),
            role_list: this.attr(""),
            created_by_msl: this.attr(""),
            location: this.attr(""),
            sex: this.attr(""),
            ethnicity: this.attr(""),
            // m-m relationships
            // partner_id: this.attr(null),
            // partner: this.belongsTo(Partner, "partner_id"),
            // kits: this.hasMany(Kit, "user"),
            // genome: this.hasMany(Genome, "user"),
            // orders: this.hasMany(Order, "user"),
            // responses: this.hasMany(Response, "user"),
            // enrollments: this.hasMany(Enrollment, "user"),
            // eligibilities: this.hasMany(Eligibility, "user_id"),
            // studies: this.belongsToMany(Study, Enrollment, "user", "study_id"),
            // conditions: this.belongsToMany(Condition, Diagnosis, "user", "condition_id"),
            // sites: this.belongsToMany(Site, SiteUser, "user_id", "site_id"),
            roles: this.belongsToMany(Role, UserRole, "user_id", "role_id"),

            // Helper attributes
            is_site: this.boolean(false),
        };
    }
}
