<template>
    <div class="min-h-screen no-visible-focus" ref="blog-top" tabIndex="-1">
        <div v-if="blogpost">
            <div class="max-w-2xl mx-auto relative mt-20 md:mt-24 lg:mt-32" id="render-element">
                <div class="md:flex">
                    <div class="w-full md:w-1/2 flex-shrink-0 mx-auto sm:mx-0">
                        <div class="mx-5 md:mr-0 lg:ml-10 xl:ml-0 relative h-full blog-header-img">
                            <LazyLoadingBGimage :mainSrc="blogpost.image_hero_620w" />
                        </div>
                    </div>
                    <div class="mt-4 md:mt-0 w-full md:w-1/2 px-6 lg:px-10 xl:pr-0 flex flex-col justify-center">
                        <p class="text-portal-sm text-lighter pt-4" style="text-transform: uppercase">
                            {{ $date(blogpost.date_published).format("D MMM, YYYY") }}
                        </p>
                        <h1 class="h2 display-text mt-6">
                            {{ blogpost.title }}
                        </h1>
                        <p v-if="blogpost.subtitle" class="mt-6 pb-4 text-lg leading-normal">
                            {{ blogpost.subtitle }}
                        </p>
                        <RichTextRenderer class="hidden lg:block pt-2" :document="blogpost.content.header" />
                    </div>
                </div>
                <div v-if="hasMultipleSections" class="block h-px bg-light-grey mt-6 md:mt-12 lg:mt-20 mx-6 lg:mx-10 xl:mx-0"></div>
            </div>
            <!-- Rich text content -->
            <div class="relative max-w-lg mx-auto px-5 lg:px-0 my-10 lg:my-16">
                <RichTextRenderer class="lg:hidden" :document="blogpost.content.header" />
                <RichTextRenderer :document="blogpost.content.body" @heading-clicked="handleHeadingClick" />
            </div>
            <div v-if="relatedBlogs && relatedBlogs.length > 0">
                <div class="pb-4 px-4 relative overflow-hidden">
                    <div class="border-t border-light-grey mt-2 mb-12 md:mb-16 w-1/2 mx-auto"></div>
                    <div class="mx-auto max-w-md">
                        <h2 class="h2 text-center mb-12 display-text">{{ strings.related }}</h2>
                    </div>
                    <Carousel
                        :totalSlides="relatedBlogs.length"
                        :perPageAtWidths="{ 0: 1, 576: 1, 768: 3 }"
                        showPrevNext
                        name="related-blogs"
                        class="max-w-xl mx-auto"
                    >
                        <!-- eslint-disable -->
                            <!-- TODO: eslint should not be complaining about this [see links below] but need to move on with the build for now -->
                            <!-- https://v3.vuejs.org/guide/migration/key-attribute.html#with-template-v-for https://github.com/vuejs/vue-next/issues/1977 -->
                            <template v-for="(relatedBlog, index) in relatedBlogs" v-slot:[`slot-${index}`]="relatedBlogs" :key="`related-blog-slide-${index}`">
                                <div class="flex justify-center" style="min-height: inherit">
                                    <BlogCard :blogData="relatedBlog" withImage :bgColourClass="blogColours[index]" style="min-height: inherit" />
                                </div>
                            </template>
                            <!-- eslint-enable -->
                    </Carousel>
                    <!-- <BlogCard :blogData="relatedBlogs[0]" withImage :bgColourClass="blogColours[0]" style="min-height: 100%;" /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Blogpost } from "@/data/models";
import LazyLoadingBGimage from "@/components/ui/lazyLoadingBGimage.vue";
import RichTextRenderer from "@/components/richText/richTextRenderer";
import Carousel from "@/components/ui/carousel";
import BlogCard from "@/components/cards/blogCard";
import { mapFields } from "vuex-map-fields";
import text from "./blogText";

export default {
    name: "Blogs",
    components: {
        LazyLoadingBGimage,
        RichTextRenderer,
        Carousel,
        BlogCard,
    },
    data() {
        return {
            blogpost: null,
            relatedBlogs: null,
            strings: text,
            blogColours: ["bg-tertiary-a", "bg-tertiary-c", "bg-tertiary-d", "bg-tertiary-b"],
        };
    },
    watch: {
        $route(newRoute) {
            // Fetch the new blog information if we go directly from one blog to another
            if (newRoute.name === "blogpost") {
                this.fetchBlogpost();
            }
        },
    },
    async mounted() {
        setTimeout(() => {
            if (this.blogpost) this.$emit("check-scroll-to");
        }, 300);
        this.fetchBlogpost();
    },
    computed: {
        ...mapFields(["lastInteractionKey"]),
        hasMultipleSections() {
            return this.blogpost?.content?.body?.content?.length > 1;
        },
    },
    methods: {
        async fetchBlogpost() {
            const blogpostKey = this.$route.params.key;
            let blogpost;

            try {
                // Admin/Team members should have access to unpublished content
                this.$auth.hasPermission("perm.blogposts.read_own")
                    ? await Blogpost.$read({ by: blogpostKey, field: "key", prefix: "/admin/read" })
                    : await Blogpost.$read({ by: blogpostKey, field: "key" });

                blogpost = Blogpost.query().where("key", blogpostKey).first();

                if (!blogpost) this.missingBlog("Sorry, that blogpost does not exist.");
            } catch (error) {
                this.missingBlog(error.message);
            }

            this.blogpost = blogpost;
            // Fetch related blogs after loading the main page
            if (blogpost.related_blogposts && blogpost.related_blogposts.length > 0) this.fetchRelatedBlogs();

            // Manage focus
            console.log(this.lastInteractionKey, this.$refs["blog-top"]);
            if (this.lastInteractionKey && this.$refs["blog-top"]) {
                this.$nextTick(() => {
                    console.log("focus");
                    this.$refs["blog-top"].focus();
                });
            }
        },
        async fetchRelatedBlogs() {
            await Blogpost.$read({ by: this.blogpost.related_blogposts, field: "key" });

            this.relatedBlogs = Blogpost.query()
                .all()
                .filter((blogpost) => {
                    return this.blogpost.related_blogposts.includes(blogpost.key);
                });
        },
        missingBlog(errorMessage) {
            this.$toastSwal.fire({
                title: errorMessage,
            });
            this.$router.push("/");
        },
        handleHeadingClick(headingVal) {
            // Updates url with clicked heading anchor
            this.$router.replace({ query: { scrollTo: headingVal }, params: { savedPosition: true } });
        },
    },
};
</script>

<style scoped>
.blog-header-img {
    min-height: 20rem;
}

@media (min-width: 992px) {
    .blog-header-img {
        min-height: 25rem;
    }
}

@media (max-width: 575px) {
    .blog-header-img {
        min-height: 12rem;
    }
}

.no-visible-focus:focus::-moz-focus-inner {
    border: 0;
    -moz-outline-style: none;
    outline: none;
}
</style>
