*/ In the privacy & terms pages, unlike the rest of the site, there's a lot of text and so I have not extracted it in the same way as 
other components & pages - it seems worthwhile to have separate rules for these 2 pages in the Smartling dashboard */
<template>
    <div class="min-h-screen pt-32 max-w-xl px-4 mx-auto">
        <div id="render-element"></div>
        <h1 class="h1 mb-8 text-primary high-c-text-black">Terms</h1>
        <p class="mb-6">
            This page (together with the documents referred to on or in it) tells you information about us and the legal terms and conditions (Terms)
            on which you take part in a study, test or research project (Activity) which is run by researchers (Researchers), through our website.
        </p>
        <p class="mb-6">Please read these Terms carefully and make sure that you understand them before participating in an Activity.</p>
        <p class="mb-16">
            We amend these Terms from time to time as set out in clause 8. Please check these terms every time you wish to participate in an Activity
            to ensure you understand the terms which will apply at that time.
        </p>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">1. Information about us</h2>
            <p class="mb-6">
                <span class="text-regular">1.1 </span>This website is operated by The Wellness League (a Fatty Liver Foundation initiative) and Sano
                Genetics Limited. The Fatty Liver Foundation is a 501(c)3 non-profit registered in the United States and our registered office address
                is 3938 E Shady Glen Ct, Boise, ID 83706, United States. Sano Genetics Limited is a company registered in England and Wales under
                company number 10636359 and our registered office address is at Salisbury House, Station Rd, Cambridge CB1 2LA.
            </p>
            <p class="mb-6"><span class="text-regular">1.2 </span>To contact us, please email gabriella@fattyliverfoundation.org</p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">Participating in an Activity</h2>
            <p class="mb-6">
                <span class="text-regular">2.1 </span>We provide various services including genetic testing and induced pluripotent stem cell
                induction. We act as an intermediary between Researchers who need data for an Activity, and individuals like you who supply the data
                for that Activity.
            </p>
            <p class="mb-6"><span class="text-regular">2.2 </span>You may only take part in an Activity if you are at least 18 years old</p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">Testing kits</h2>
            <p class="mb-6">
                <span class="text-regular">3.1 </span>If necessary for an Activity, we will send you a testing kit so that you can provide us with a
                blood or saliva sample. Depending on the Activity, a testing kit may either be free of charge or for a fee. We will let you know
                whether a fee will be charged for a testing kit before you agree to participate in an Activity.
            </p>
            <p class="mb-6">
                <span class="text-regular">3.2 </span>Once you have provided us with your sample and returned the testing kit to us, we will send your
                sample to a third party who will analyse it for us, so we can then send it to the Researchers for the purpose of carrying out the
                Activity.
            </p>
            <p class="mb-6">
                <span class="text-regular">3.3 </span>Although we will make every effort to be as accurate as possible, given the nature of some
                Activities, there is margin for error. We will use reasonable endeavours to ensure that any analysis of your sample is accurate.
            </p>
            <p class="mb-6">
                <span class="text-regular">3.4 </span>In the unlikely event of an incorrect analysis, we may, at our discretion, provide you with
                another testing kit, free of charge, or refund your original payment.
            </p>
            <p class="mb-6">
                <span class="text-regular">3.5 </span>Any samples, drawings, descriptive matter, advertising or illustrations on our site are for the
                sole purpose of giving an approximate idea of the Activities. They shall not form part of our contract with you or have any
                contractual force.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">Price for the testing kit and contract formation</h2>
            <p class="mb-6">
                <span class="text-regular">4.1 </span>If you are required to pay for a testing kit, we will notify you of the price before the order
                is placed.
            </p>
            <p class="mb-6">
                <span class="text-regular">4.2 </span>Prices are liable to change at any time, but changes will not affect orders in respect of which
                we have already sent you an email confirming your order for a testing kit (Dispatch Confirmation).
            </p>
            <p class="mb-6"><span class="text-regular">4.3 </span>Your payment will be processed by Stripe.</p>
            <p class="mb-6">
                <span class="text-regular">4.4 </span>The price of the testing kit will be as quoted on our site from time to time. We take all
                reasonable care to ensure that the prices of testing kit are correct at the time when the relevant information was entered onto the
                system. However, if we discover an error in the price of a testing kit you ordered, please see clause 4.6 for what happens in this
                event.
            </p>
            <p class="mb-6">
                <span class="text-regular">4.5 </span>The price of a testing kit includes VAT (where applicable) at the applicable current rate
                chargeable in the UK for the time being.
            </p>
            <p class="mb-6">
                <span class="text-regular">4.6 </span>If we discover an error in the price of the testing kit you have ordered we will contact you to
                inform you of this error and we will give you the option of continuing to purchase the testing kit at the correct price or cancelling
                your order. We will not process your order until we have your instructions. If we are unable to contact you using the contact details
                you provided during the order process, we will treat the order as cancelled and notify you in writing.
            </p>
            <p class="mb-6">
                <span class="text-regular">4.7 </span>Our contract with you (Contract) will form once we have sent you the Dispatch Confirmation.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">5. Fee for the Activity</h2>
            <p class="mb-6">
                <span class="text-regular">5.1 </span>You may receive a fee from the Researchers in consideration of your participation in an Activity
                (Fee). The amount of the Fee will be notified to you when the Activity is first advertised on our website, and before you agree to
                participate in the Activity.
            </p>
            <p class="mb-6">
                <span class="text-regular">5.2 </span>We will issue the Fee to you as soon as possible and within at most 28 days after the Activity
                has concluded.
            </p>
            <p class="mb-6">
                <span class="text-regular">5.3 </span>Please note that we act as an intermediary between the Researchers and you. We are therefore not
                responsible or liable towards you for any acts or omissions of the Researchers.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">6. Your obligations</h2>
            <p class="mb-4"><span class="text-regular">6.1 </span>You shall:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">6.1.1 </span>co-operate with us in all matters relating to the Activity you have chosen to participate
                    in.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">6.1.2 </span>provide us with such information, samples and materials as we may reasonably require so
                    that you can take part in the Activity, and ensure that such information is accurate in all material respects.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">6.1.3 </span>keep and maintain all materials, testing kits, documents and other property of ours in
                    safe custody and in good condition until returned to us and not dispose of or use them other than in accordance with our
                    instructions.
                </li>
            </ul>
            <p class="mb-6">
                <span class="text-regular">6.2 </span>If our performance of any of our obligations under this Contract is prevented or delayed by any
                act or omission by you or failure by you to perform any relevant obligation (Your Default) we shall have the right to suspend your
                participation in the Activity until you remedy Your Default, and to rely on Your Default to relieve us from the performance of our
                obligations to the extent that Your Default prevents or delays our performance of any of our obligations.
            </p>
            <p class="mb-4"><span class="text-regular">6.3 </span>You warrant that:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">6.3.1 </span>you are legally entitled to possession of the samples you provide to us. We recommend you
                    seek independent legal advice about your entitlement to take or obtain samples of biological material from persons other than
                    yourself.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">6.3.2 </span>you are not suffering from mental disorder (within the meaning of the Mental Health Act
                    1983) and are capable of understanding the nature and purpose of the test.
                </li>
            </ul>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">7. How we use your personal information</h2>
            <p class="mb-6">
                <span class="text-regular">7.1 </span>For the purposes of this clause, Personal Data has the meaning set out in the General Data
                Protection Regulation (GDPR).
            </p>
            <p class="mb-4"><span class="text-regular">7.2 </span>We will collect your Personal Data when:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug"><span class="text-regular">7.2.1 </span>you create an account with us.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">7.2.2 </span>make a payment to us.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">7.2.3 </span>provide us with samples from testing kits.</li>
            </ul>
            <p class="mb-4"><span class="text-regular">7.3 </span>Our lawful basis for processing your Personal Data is:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">7.3.1 </span>processing is necessary for the performance of the Contract with you, in respect of the
                    Personal Data you provide when creating an account with us and making a payment to us.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">7.3.2 </span>your explicit consent, in respect of the Personal Data contained in the samples.
                </li>
            </ul>
            <p class="mb-6">
                <span class="text-regular">7.4 </span>In order for us to comply with your request to partake in an Activity, we may be required to
                share your Personal Data with third parties responsible for genetic testing, induced pluripotent stem cell induction, or the
                Researcher responsible for the Activity.
            </p>
            <p class="mb-6">
                <span class="text-regular">7.5 </span>We only use your Personal Data in accordance with our Privacy Policy. Please take the time to
                read this, as it includes important terms which apply to you.
            </p>
            <p class="mb-6">
                <span class="text-regular">7.6 </span>We will retain all samples from testing kits for 20 years before destroying them securely,
                unless you have consented to us using your sample for any follow-up studies, in which case we will retain your sample for a further 20
                years.
            </p>
            <p class="mb-6">
                <span class="text-regular">7.7 </span>We will retain all results and other information for 20 years from the date of production before
                destroying them securely.
            </p>
            <p class="mb-6">
                <span class="text-regular">7.8 </span>If you have any questions about how we handle your personal data, please contact us at
                will@sanogenetics.com.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">8. Our right to vary these terms</h2>
            <p class="mb-4"><span class="text-regular">8.1 </span>We may revise these Terms from time to time in the following circumstances:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug"><span class="text-regular">8.1.1 </span>changes in how we accept payment from you.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">8.1.2 </span>changes in relevant laws and regulatory requirements.</li>
            </ul>
            <p class="mb-6">
                <span class="text-regular">8.2 </span>Whenever we revise these Terms in accordance with this clause 8, we will keep you informed and
                give you notice of this by stating that these Terms have been amended and the relevant date at the top of them.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">9. Your consumer right of return and refund</h2>
            <p class="mb-6">
                <span class="text-regular">9.1 </span>You have a legal right to cancel a Contract under the consumer legislation during the period set
                out below in clause 9.3. This means that during the relevant period if you change your mind or for any other reason you decide you do
                not want to proceed, you can notify us of your decision to cancel the Contract and receive a refund.
            </p>
            <p class="mb-6">
                <span class="text-regular">9.2 </span>Your cancellation rights in relation to clause 9.1 are set out in clauses below. Please note
                that these cancellation rights do not apply in the case of any services which are clearly personalised.
            </p>
            <p class="mb-6">
                <span class="text-regular">9.3 </span>You have a right to cancel a Contract within 14 days after the day on which the Dispatch
                Confirmation is sent to you.
            </p>
            <p class="mb-6">
                <span class="text-regular">9.4 </span>To cancel a Contract, please fill out and submit the Model Cancellation Form (see below).
            </p>
            <p class="mb-6">
                <span class="text-regular">9.5 </span>You will receive a full refund of the price you paid for a testing kit. We will process the
                refund due to you as soon as possible and, in any case, within 14 days of the day on which you gave us notice of cancellation as
                described in clause 9.4.
            </p>
            <p class="mb-6"><span class="text-regular">9.6 </span>We refund you on the credit card or debit card used by you to pay.</p>
            <p class="mb-4"><span class="text-regular">9.7 </span>If a testing kit was delivered to you:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">9.7.1 </span>you must return the testing kit to us as soon as reasonably practicable.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">9.7.2 </span>you have a legal obligation to keep the testing kit in your possession and to take
                    reasonable care of the testing kit while it is your possession.
                </li>
            </ul>
            <p class="mb-6">
                <span class="text-regular">9.8 </span>For any further information about your rights as a consumer, please visit the Citizens Advice
                website: https://www.citizensadvice.org.uk/.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">10. Shipping</h2>
            <p class="mb-6">
                <span class="text-regular">10.1 </span>The testing kit will be shipped by the estimated shipping date set out in the Dispatch
                Confirmation, unless there is an event outside of our control (Force Majeure Event). Please refer to clause 12 for more information
                about Force Majeure Events.
            </p>
            <p class="mb-6">
                <span class="text-regular">10.2 </span>Shipping will be completed when we ship the testing kit to the address you gave us determined
                by the carrier sending out a tracking number to you.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">11. Our liability towards you</h2>
            <p class="mb-6">
                <span class="text-regular">11.1 </span>If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is
                a foreseeable result of our breach of these Terms or our negligence, but we are not responsible for any loss or damage that is not
                foreseeable. Loss or damage is foreseeable if they were an obvious consequence of our breach or if they were contemplated by you and
                us at the time we entered into the Contract.
            </p>
            <p class="mb-6">
                <span class="text-regular">11.2 </span>We only supply the testing kit for domestic and private use. You agree not to use the testing
                kit for any commercial, business or re-sale purposes, and we have no liability to you for any loss of profit, loss of business,
                business interruption, or loss of business opportunity.
            </p>
            <p class="mb-4"><span class="text-regular">11.3 </span>We do not in any way exclude or limit our liability for:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug"><span class="text-regular">11.3.1 </span>death or personal injury caused by our negligence.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">11.3.2 </span>fraud or fraudulent misrepresentation.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">11.3.3 </span>any terms which cannot be excluded under applicable law.</li>
            </ul>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">12. Force Majeure</h2>
            <p class="mb-6">
                <span class="text-regular">12.1 </span>We will not be liable or responsible for any failure to perform, or delay in performance of,
                any of our obligations under a Contract that is caused by a Force Majeure Event. A Force Majeure Event is defined below in clause
                12.2.
            </p>
            <p class="mb-6">
                <span class="text-regular">12.2 </span>A Force Majeure Event means any act or event beyond our reasonable control, including without
                limitation strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat
                of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake,
                subsidence, epidemic or other natural disaster, or failure of public or private telecommunications networks or impossibility of the
                use of railways, shipping, aircraft, motor transport or other means of public or private transport.
            </p>
            <p class="mb-4">
                <span class="text-regular">12.3 </span>If a Force Majeure Event takes place that affects the performance of our obligations under a
                Contract:
            </p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">12.3.1 </span>we will contact you as soon as reasonably possible to notify you.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">12.3.2 </span>our obligations under a Contract will be suspended and the time for performance of our
                    obligations will be extended for the duration of the Force Majeure Event. Where the Force Majeure Event affects our shipping of
                    testing kits to you, we will arrange a new shipping date with you after the Force Majeure Event is over.
                </li>
            </ul>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">13. Communications between us</h2>
            <p class="mb-6"><span class="text-regular">13.1 </span>When we refer, in these Terms, to "in writing", this will include e-mail.</p>
            <p class="mb-6">
                <span class="text-regular">13.2 </span>If you wish to contact us in writing, you can send this to us by e-mail to
                gabriella@fattyliverfoundation.org
            </p>
            <p class="mb-6">
                <span class="text-regular">13.3 </span>If we have to contact you or give you notice in writing, we will do so by e-mail or by pre-paid
                post to the address you provide to us in your order.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">14. Prizes, Contests and Giveaways</h2>
            <p class="mb-6">
                <span class="text-regular">14.1 </span>The promoter is: The Wellness League (a Fatty Liver Foundation initiative) and Sano Genetics
                Limited. The Fatty Liver Foundation is a 501(c)3 non-profit registered in the United States and our registered office address is 3938
                E Shady Glen Ct, Boise, ID 83706, United States. Sano Genetics Limited is a company registered in England and Wales under company
                number 10636359 and our registered office address is at Salisbury House, Station Rd, Cambridge CB1 2LA.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.2 </span>Contests, prizes and giveaways are open to individuals aged 18 years or over except employees
                of the Fatty Liver Foundation and Sano Genetics Limited and their close relatives and anyone otherwise connected with the
                organisations.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.3 </span>The rules of each prize draw, contest or giveaway are specified in each prize draw, contest or
                giveaway and are supplementary to these terms and conditions.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.4 </span>The promoter reserves the right to cancel or amend any prize draw, contest or giveaway and
                these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God or any actual
                or anticipated breach of any applicable law or regulation or any other event outside of the promoter’s control. Any changes to a prize
                draw, contest or giveaway will be notified to entrants as soon as possible by promoter.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.5 </span>Prizes are subject to availability and we reserve the right to substitute any prize with
                another of equivalent value without giving notice.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.6 </span>Winners will be chosen: at random, from all entries received and verified by Promoter and or
                its agents. Additional criteria for verification may be specified in the original post or email referring to the giveaway. The
                promoter will notify the winner when and where the prize can be collected / is delivered.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.7 </span>The prize draw and these terms and conditions will be governed by English law and State law and
                any disputes will be subject to the exclusive jurisdiction of the Courts and the State.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.8 </span>The winners agree to the use of his/her name and image in any publicity material. Any personal
                data relating to the winners or any other entrants will be used solely in accordance with current UK data protection legislation and
                will not be disclosed to a third party without the entrant’s prior consent.
            </p>
            <p class="mb-6">
                <span class="text-regular">14.9 </span>No promotion is in anyway sponsored, endorsed or administered by, or associated with, Facebook,
                Twitter or any other Social Network. You are providing your information to The Wellness League (a Fatty Liver Foundation initiative)
                and Sano Genetics Limited and not to any other party. The information provided will be used in line with our Privacy Policy found
                at https://thewellnessleague.org/privacy
            </p>
        </section>
        <section class="pt-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">15. Other important terms</h2>
            <p class="mb-6">
                <span class="text-regular">15.1 </span>We may transfer our rights and obligations to another organisation, but this will not affect
                your rights or our obligations under these Terms.
            </p>
            <p class="mb-6">
                <span class="text-regular">15.2 </span>You may only transfer your rights or your obligations under these Terms to another person if we
                agree in writing.
            </p>
            <p class="mb-6">
                <span class="text-regular">15.3 </span>This contract is between you and us. No other person shall have any rights to enforce any of
                its terms, whether under the Contracts (Rights of Third Parties Act) 1999 or otherwise.
            </p>
            <p class="mb-6">
                <span class="text-regular">15.4 </span>Each of the paragraphs of these Terms operates separately. If any court or relevant authority
                decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.
            </p>
            <p class="mb-6">
                <span class="text-regular">15.5 </span>If we fail to insist that you perform any of your obligations under these Terms, or if we do
                not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will
                not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and
                that will not mean that we will automatically waive any later default by you.
            </p>
            <p class="mb-6">
                <span class="text-regular">15.6 </span>These Terms are governed by English law and State law. You and we both agree that the courts of
                England and Wales and the State will have non-exclusive jurisdiction. However, if you are a resident of Northern Ireland you may also
                bring proceedings in Northern Ireland, and if you are a resident of Scotland, you may also bring proceedings in Scotland.
            </p>
            <p class="text-regular">Model Cancellation Form</p>
            <p class="mb-2 leading-tight">(Complete and return this form only if you wish to withdraw from the contract)</p>
            <p class="mb-2 leading-tight">To Sano Genetics Limited, Salisbury House, Station Rd, Cambridge CB1 2LA</p>
            <p class="mb-2 leading-tight">
                I/We [*] hereby give notice that I/We [*] cancel my/our [*] contract of sale of the following goods [*]/for the supply of the
                following service [*],
            </p>
            <p class="mb-2 leading-tight">Ordered on [*]/received on [*],</p>
            <p class="mb-2 leading-tight">Name of consumer(s), Address of consumer(s),</p>
            <p class="mb-2 leading-tight">Signature of consumer(s) (only if this form is notified on paper),</p>
            <p class="mb-2 leading-tight">Date</p>
            <p class="mb-2 leading-tight">[*] Delete as appropriate</p>
        </section>
    </div>
</template>

<script>
export default {
    name: "Terms",
};
</script>
