import BaseModel from "./BaseModel";
import { Role, RolePermission } from "../models";

export default class Permission extends BaseModel {
    static entity = "permissions";

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(""),
            description: this.attr(""),

            // m-m relationships
            roles: this.belongsToMany(Role, RolePermission, "permission_id", "role_id"),
        };
    }
}
