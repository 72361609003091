<template>
    <div class="px-0 mt-5 text-sm">
        <span>{{ node.content.length > 1 ? strings.top : strings.credit }}</span>
        <span v-for="({ content }, index) in imageCredits" :key="index">
            <!-- The text is contained within: image_credit.content[] -> paragraph.content[] -->
            {{ content[0].content[0].text + addSeparator(index) }}
        </span>
    </div>
</template>

<script>
import text from "./imageCreditText";

export default {
    name: "ImageCredit",
    data() {
        return {
            strings: text,
        }
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
    },
    computed: {
        imageCredits() {
            // Filters any paragraph node that does not contain any text
            return this.node.content.filter(({ content }) => content[0].content);
        },
    },
    methods: {
        addSeparator(index) {
            // Adds a comma if index is not the last one in the list
            return index + 1 < this.imageCredits.length ? ", " : "";
        },
    },
};
</script>
