/* eslint-disable no-unused-vars */
import { slugify } from "@/js/helpers/slugify";

const slugifyHeading = (node) => {
    const headingText = node.content && node.content.length && node.content[0].text;
    if (!headingText) {
        console.error("Text content not found in node:", node);
        return null;
    }
    return slugify(headingText);
};

export default {
    nodes: {
        horizontal_rule(node) {
            return {
                tag: "hr",
            };
        },
        blockquote(node) {
            return {
                tag: "blockquote",
            };
        },
        bullet_list(node) {
            return {
                tag: "ul",
            };
        },
        code_block(node) {
            return {
                tag: [
                    "pre",
                    {
                        tag: "code",
                        attrs: node.attrs,
                    },
                ],
            };
        },
        hard_break(node) {
            return {
                tag: "br",
            };
        },
        heading(node) {
            const anchorId = slugifyHeading(node);
            return {
                tag: `h${node.attrs.level}`,
                data: { id: anchorId },
            };
        },
        list_item(node) {
            return {
                tag: "li",
            };
        },
        ordered_list(node) {
            return {
                tag: "ol",
            };
        },
        paragraph(node) {
            return {
                tag: "p",
            };
        },
        styled_paragraph(node) {
            return {
                tag: "p",
                data: { ...node.attrs },
            };
        },
    },
    marks: {
        bold() {
            return {
                tag: "strong",
            };
        },
        // reference(node) {
        //     return {
        //         tag: TextReference,
        //         skipContent: true,
        //     };
        // },
        strike() {
            return {
                tag: "strike",
            };
        },
        underline() {
            return {
                tag: "u",
            };
        },
        strong() {
            return {
                tag: "strong",
            };
        },
        code() {
            return {
                tag: "code",
            };
        },
        italic() {
            return {
                tag: "i",
            };
        },
        link(node) {
            const attrs = { ...node.attrs },
                { linktype = "url" } = node.attrs;

            if (linktype === "email") attrs.href = `mailto:${attrs.href}`;

            if (attrs.anchor) {
                attrs.href = `${attrs.href}#${attrs.anchor}`;
                delete attrs.anchor;
            }

            return {
                tag: "a",
                data: { ...attrs },
            };
        },
        styled(node) {
            return {
                tag: "span",
                data: { ...node.attrs },
            };
        },
    },
};
