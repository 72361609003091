<template>
    <div class="max-w-xs rounded w-full h-full flex flex-col relative bg-primary text-over-primary">
        <div class="relative blog-card-img rounded-t overflow-hidden">
            <LazyLoadingBGimage :mainSrc="blogData.image_thumbnail_400w_300h" />
        </div>
        <div class="px-5 lg:px-6 py-1 flex-grow flex flex-col justify-between">
            <div>
                <h3 class="mb-3 md:mb-6 mt-4 pb-1">{{ blogData.title }}</h3>
                <!-- <p class="text-portal-sm">{{ blogData.subtitle }}</p> -->
            </div>
            <div class="uppercase text-sm pb-4">
                <!-- <span v-if="blogData.author">By {{ blogData.author }}, </span> -->
                <span>{{ $date(blogData.date_published).format("MMMM DD YYYY") }}</span>
            </div>
        </div>
        <div class="absolute left-0 top-0 right-0 bottom-0">
            <router-link
                :to="`/blog/${blogData.key}`"
                class="absolute left-0 top-0 w-full h-full rounded block"
                :aria-label="`${blogData.title} ${strings.more}`"
            ></router-link>
        </div>
    </div>
</template>

<script>
import LazyLoadingBGimage from "@/components/ui/lazyLoadingBGimage";
import text from "./blogCardText";

export default {
    name: "BlogCard",
    components: {
        LazyLoadingBGimage,
    },
    data() {
        return {
            strings: text,
        }
    },
    props: {
        blogData: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.blog-card-img {
    padding-top: 82.5%;
}

@media (max-width: 575px) {
    .blog-card-img {
        padding-top: 50%;
    }
}
</style>
