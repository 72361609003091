import configs from "../portal-configs.js";

export default {
    install: (app, org_name_from_env) => {
        app.mixin({
            computed: {
                portalConfig() {
                    return configs[org_name_from_env];
                },
            },
        });
    },
};
