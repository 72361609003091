<template>
    <div :class="`loading-spinner ${borderColourClass} ${borderWidthClass}`" :style="`width: ${diameterRem}rem; height: ${diameterRem}rem;`"></div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        borderWidthClass: {
            type: String,
            default: "border-4",
        },
        borderColourClass: {
            type: String,
            default: "border-black",
        },
        diameterRem: {
            type: Number,
            default: 2,
        },
    },
};
</script>

<style scoped>
.loading-spinner {
    @apply mx-auto;
    overflow: hidden;
    border-left: 0.2rem solid rgba(255, 255, 255, 0);
    transform: translateZ(0);
    animation: LoadingSpinner 1.1s infinite linear;
}

.loading-spinner,
.loading-spinner:after {
    border-radius: 50%;
}

.loading-spinner:after {
    @apply w-full h-full;
}

@keyframes LoadingSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
