<template>
    <div id="render-element">
        <!-- header -->
        <div class="w-full overflow-hidden bg-primary relative">
            <div v-if="headerSect" class="px-4 xs:px-6 lg:px-16 max-w-2xl mx-auto md:flex pt-16 pb-6 md:py-24 md:py-32 lg:py-48">
                <div class="md:hidden text-over-primary text-center">
                    <p v-if="headerSect.overline" class="overline mt-12 mb-4">{{ headerSect.overline }}</p>
                    <h1 class="block h1 mb-8">
                        <span style="line-height: 0.85" class="block">{{ headerSect.title }}</span>
                    </h1>
                </div>
                <div class="w-full md:w-1/3 lg:w-1/2 relative order-1">
                    <div class="md:absolute header-img">
                        <LazyLoadingBGimage
                            backgroundSizePosClasses="bg-contain bg-center"
                            mainSrc="https://media.sanogenetics.com/portal-flf/flf-landing-hero-750w.png"
                        />
                    </div>
                </div>
                <div class="w-full md:w-2/3 lg:w-1/2 md:pr-16 text-over-primary text-center md:text-left">
                    <p v-if="headerSect.overline" class="overline mt-10 lg:mt-0 hidden md:block mb-6">{{ headerSect.overline }}</p>
                    <!-- header section title should be 'required' -->
                    <h1 class="h1 hidden md:block" data-cy="landing-desktop-h1">
                        <span style="line-height: 0.85" class="block">{{ headerSect.title }}</span>
                    </h1>
                    <p class="py-8 max-w-sm leading-normal text-base lg:text-lg mx-auto md:mx-0" v-if="headerSect.text">{{ headerSect.text }}</p>
                    <div class="hidden md:block">
                        <router-link v-if="portalConfig.login_allowed" to="/register" class="btn bg-white border-white text-primary">
                            <span class="px-4 md:px-8">{{ strings.signup }}</span>
                        </router-link>
                        <JoinEmailList v-else showWhite />
                    </div>
                </div>
            </div>
            <div class="absolute left-0 right-0 bottom-0 h-16 hidden lg:block">
                <div class="w-8 h-16 mx-auto -mt-6">
                    <svg viewBox="0 0 30 41" class="fill-current text-white use-svg">
                        <path d="M15.2857 40L1 25.7746M15.2857 40L29.5714 25.7746M15.2857 40V0" />
                    </svg>
                </div>
            </div>
        </div>
        <!-- about -->
        <div v-if="aboutSect" class="text-center mx-auto max-w-md px-6 mt-20 sm:mt-24 lg:mt-32 lg:pt-6 xl:pt-8">
            <p v-if="aboutSect.overline" class="overline mb-4 md:mb-6">{{ aboutSect.overline }}</p>
            <h2 v-if="aboutSect.title" class="block h1 text-primary high-c-text-black">{{ aboutSect.title }}</h2>
            <p v-if="aboutSect.text" class="pt-8 lg:px-8">{{ aboutSect.text }}</p>
        </div>

        <section class="mt-20 sm:mt-24 lg:mt-32 px-4 overflow-hidden">
            <!-- Accordion on mobile, tabs on tablet+ -->
            <div class="md:hidden mb-4 border-b border-light-grey max-w-sm mx-auto">
                <Accordion
                    v-for="(accordion, index) in featureInfoSets"
                    :name="`feature-accordion-${index}`"
                    :key="`feature-accordion-${index}`"
                    showPlusMinus
                    buttonClosedClasses="text-left py-6 border-t border-light-grey"
                    buttonOpenClasses="text-left py-6 border-t-2 border-primary"
                >
                    <template v-slot:insideButton>
                        {{ accordion.label }}
                    </template>
                    <template v-slot:content>
                        <div class="w-full relative bg-mid-grey rounded-lg h-32 xs:h-48">
                            <LazyLoadingBGimage :mainSrc="accordion.image" />
                        </div>
                        <div class="py-6 border-b border-light-grey">
                            <div v-if="accordion.comingSoon" class="w-32 mb-6">
                                <div class="bg-primary high-c-bg-black text-over-primary text-portal-xs text-center p-2 leading-none rounded mr-6">
                                    {{ strings.soon }}
                                </div>
                            </div>
                            <div>
                                <h3 class="h3 display-text max-w-xs">{{ accordion.title }}</h3>
                                <p class="mt-4">{{ accordion.text }}</p>
                            </div>
                            <div v-if="accordion.learnMoreLink" class="mt-6">
                                <router-link :to="accordion.learnMoreLink" class="btn border">{{ strings.more }}</router-link>
                            </div>
                        </div>
                    </template>
                </Accordion>
            </div>
            <!-- Set up a separate set of 'goToSlide controls' for this instance as they are quite specific looking -->
            <!-- Also if we can pass in 'goToSlide' changes this gives us extra flexibility -->
            <div class="hidden md:block max-w-2xl mx-auto p-6 lg:p-8 lg:pt-4">
                <div class="border-b border-light-grey mb-12">
                    <ul class="flex relative">
                        <li
                            v-for="(slide, index) in featureInfoSets"
                            :key="`caro-control-button-${index}`"
                            :style="`width: ${100 / featureInfoSets.length}%;`"
                            class="block relative text-center"
                        >
                            <button
                                @click="slideJumpTo(index)"
                                :aria-label="`${featuresCurrentSlide === index ? strings.active : strings.jump} ${slide.label}`"
                                class="jump-to-btn text-mid-grey high-c-text-dark-grey"
                            >
                                <span class="text-base p-4 leading-none block" :class="{ active: featuresCurrentSlide === index }">{{
                                    slide.label
                                }}</span>
                            </button>
                            <div
                                :class="{ active: featuresCurrentSlide === index }"
                                class="w-full h-1 rounded-full bg-primary absolute left-0 indicator"
                                style="bottom: -2px"
                            ></div>
                        </li>
                    </ul>
                </div>
                <!-- In this instance the 'slider' will act as tabs, but the same component can handle this -->
                <Carousel
                    v-if="featureInfoSets"
                    :totalSlides="featureInfoSets.length"
                    :updateCurrentSlideTo="updateCurrentSlideTo"
                    noSlide
                    name="features"
                    @current-slide-change-to="updateSlideFromCarousel"
                >
                    <!-- eslint-disable -->
                    <!-- TODO: eslint should not be complaining about this [see links below] but need to move on with the build for now -->
                    <!-- https://v3.vuejs.org/guide/migration/key-attribute.html#with-template-v-for https://github.com/vuejs/vue-next/issues/1977 -->
                    <!-- https://www.gitmemory.com/issue/vuejs/eslint-plugin-vue/1279/676843446 -->
                    <template v-for="(slide, index) in featureInfoSets" v-slot:[`slot-${index}`]="featureInfoSets" :key="`landing-feature-slide-${index}`">
                        <div class="flex" style="min-height: inherit;">
                            <div class="w-1/2 lg:w-1/3 flex flex-col justify-between" style="min-height: inherit;">
                                <div v-if="slide.comingSoon" class="w-32 mb-6">
                                    <div class="bg-primary high-c-bg-black text-over-primary text-portal-xs text-center p-2 leading-none rounded mr-6">
                                        {{ strings.soon }}
                                    </div>
                                </div>
                                <div>
                                    <h3 class="h3 display-text">{{ slide.title }}</h3>
                                    <p class="mt-4">{{ slide.text }}</p>
                                </div>
                                <div v-if="slide.learnMoreLink" class="mt-6">
                                    <router-link :to="slide.learnMoreLink" class="btn border">
                                        {{ strings.more }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="w-1/2 lg:w-2/3 pl-4 lg:pl-8 xl:pl-12 h-full" style="min-height: inherit;">
                                <div class="w-full relative bg-primary rounded-lg" style="min-height: inherit;">
                                    <LazyLoadingBGimage :mainSrc="slide.image" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- eslint-enable -->
                </Carousel>
            </div>
        </section>

        <section v-if="blogsToShow.length > 0" class="mt-16 sm:mt-20 md:mt-24 lg:mt-32 relative px-4">
            <div v-if="contentSect" class="max-w-sm lg:max-w-lg mx-auto text-center">
                <p v-if="contentSect.overline" class="overline mb-4 md:mb-6">{{ contentSect.overline }}</p>
                <h2 v-if="contentSect.title" class="h2 display-text mb-10 sm:mb-12 lg:mb-20 text-primary high-c-text-black">
                    {{ contentSect.title }}
                </h2>
            </div>
            <div class="max-w-2xl mx-auto px-3 flex flex-wrap justify-center relative z-10">
                <div v-for="(blog, index) in blogsToShow" :key="`blog-slide-${index}`" class="w-full max-w-xs sm:w-1/2 lg:w-1/4 px-3 mb-6">
                    <BlogCard :blogData="blog" />
                </div>
            </div>
            <div
                class="bg-gradient-to-r from-tertiary-a to-tertiary-b absolute top-0 left-0 w-full py-32 hidden lg:block"
                style="margin-top: 23.95rem"
            ></div>
        </section>
        <div v-if="footnotes" class="max-w-2xl px-4 sm:px-6 lg:px-8 mx-auto mt-16 md:mt-24 lg:mt-32">
            <div class="lg:pt-20">
                <div class="max-w-lg mx-auto">
                    <h3 class="h3 display-text">Footnotes</h3>
                    <ol class="text-base mt-5">
                        <li v-for="(note, i) in footnotes" :key="`footnote-${i}`" class="mb-4 flex">
                            <span class="pr-5 sm:pr-8 block">{{ i + 1 }}.</span>
                            <span class="block">
                                {{ note }}
                            </span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Blogpost } from "@/data/models";
import Carousel from "@/components/ui/carousel";
import LazyLoadingBGimage from "@/components/ui/lazyLoadingBGimage";
import BlogCard from "@/components/cards/blogCard";
import Accordion from "@/components/ui/accordion";
import JoinEmailList from "@/components/navigation/joinEmailList";
import text from "./landingText";

export default {
    name: "Landing",
    components: {
        Carousel,
        LazyLoadingBGimage,
        BlogCard,
        Accordion,
        JoinEmailList,
    },
    data() {
        return {
            updateCurrentSlideTo: null,
            blogColours: ["bg-tertiary-a", "bg-tertiary-b", "bg-tertiary-c", "bg-tertiary-d"],
            featuresCurrentSlide: 0,
            strings: text,
        };
    },
    async mounted() {
        await Blogpost.$read();
    },
    computed: {
        headerSect() {
            return this.portalConfig?.landing?.header;
        },
        aboutSect() {
            return this.portalConfig?.landing?.about;
        },
        featureInfoSets() {
            return this.portalConfig?.landing?.featureInfoSets;
        },
        contentSect() {
            return this.portalConfig?.landing?.content;
        },
        footnotes() {
            return this.portalConfig?.landing?.footnotes;
        },
        blogsToShow() {
            const time_now = new Date().getTime();

            // Potentially later on we will fetch & show blogs which are named in config rather than the latest 4
            return Blogpost.query()
                .where("date_published", (dt) => new Date(dt).getTime() < time_now)
                .where("published", true)
                .orderBy("date_published", "desc")
                .limit(4)
                .get();
        },
    },
    methods: {
        slideJumpTo(index) {
            this.updateCurrentSlideTo = index;
        },
        updateSlideFromCarousel(index) {
            this.featuresCurrentSlide = index;
        },
    },
};
</script>

<style scoped>
.jump-to-btn.active,
.jump-to-btn:hover,
.jump-to-btn:focus {
    @apply text-black;
}

.indicator:not(.active) {
    opacity: 0;
}

.jump-to-btn:focus + .indicator,
.jump-to-btn:hover + .indicator {
    opacity: 0.9;
}

.header-img {
    width: 105%;
    left: 0;
    height: 160%;
    top: -30%;
}

@media (min-width: 1200px) {
    .header-img {
        left: 5%;
    }
}

@media (max-width: 991px) {
    .header-img {
        top: -28%;
        left: -10%;
        width: 126%;
    }
}

@media (max-width: 767px) {
    .header-img {
        @apply max-w-sm h-48;
    }
}
</style>
