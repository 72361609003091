<template>
    <div id="wrapper" class="min-h-screen flex flex-col bg-background">
        <!-- Svgs symbols which will be used repeatedly across the site -->
        <svgSymbols />
        <div class="flex-grow">
            <!-- Navigation -->
            <MinimalNav v-if="pageHasMinimalNav" />
            <TopNav v-else :whiteNavToStart="pageHasWhiteNavToStart" />
            <!-- <CookieBar /> -->
            <!-- Router view -->
            <div class="h-auto min-h-screen overflow-hidden" :class="{ 'pb-16 md:pb-24': !pageHasMinimalNav && $route.name !== 'find-help' }">
                <router-view @check-scroll-to="checkScrollTo"></router-view>
            </div>
            <!-- Footer -->
            <Footer v-if="!pageHasMinimalNav" />
        </div>
    </div>
</template>

<script>
import TopNav from "@/components/navigation/topNav";
import MinimalNav from "@/components/navigation/minimalNav";
import Footer from "@/components/navigation/footer";
import svgSymbols from "@/components/svgSymbols";
// import CookieBar from "@/components/notifications/cookieBar";
import debounce from "lodash.debounce";
import { mapFields } from "vuex-map-fields";

export default {
    name: "Wrapper",
    components: {
        TopNav,
        MinimalNav,
        Footer,
        svgSymbols,
        // CookieBar,
    },
    data() {
        return {
            // Some pages start with the menu showing e.g. over an image, with the background appearing only on scroll
            // For these ones, the nav should show in white initially, and switch to black once scroll begins
            routesNamesWithWhiteNavToStart: ["landing", "home"],
            routeNamesWithMinimalNav: [
                "register",
                "register-thanks",
                "register-complete",
                "not-found",
                "password-lost",
                "password-reset",
                "login",
                "welcome",
                "survey",
            ],
            pageHasWhiteNavToStart: true,
            pageHasMinimalNav: false,
        };
    },
    mounted() {
        this.checkRouteVariations(this.$route);
        // Add this here so that we only have one listener for resizes
        window.addEventListener("resize", () => {
            this.onResize(this);
        });
    },
    unmounted() {
        window.removeEventListener("resize", () => {
            this.onResize(this);
        });
    },
    computed: {
        ...mapFields(["resizeCount"]),
    },
    watch: {
        $route(val) {
            console.log("route change!");
            this.checkRouteVariations(val);
        },
    },
    methods: {
        checkRouteVariations(currentRoute) {
            // console.log(currentRoute.name);
            this.pageHasWhiteNavToStart = this.routesNamesWithWhiteNavToStart.includes(currentRoute.name);
            this.pageHasMinimalNav = this.routeNamesWithMinimalNav.includes(currentRoute.name);
        },
        onResize: debounce((vm) => {
            vm.resizeCount = vm.resizeCount + 1;
        }, 100),
        checkScrollTo() {
            // For scrollTo to work, use links like this: :to="{ path: `/survey/${survey.id}`, query: { scrollTo: 'surveys-sect' } }"
            // or /page?scrollTo=surveys-sect and remember to include the focus element e.g. <div id="surveys-sect"></div>
            // You also need to do 'this.$emit("check-scroll-to"); when the page is loaded to make sure this is checked at the right time
            const scrollTarget = this.$route.query.scrollTo; 
            console.log("check scroll to", scrollTarget);
            setTimeout(() => {
                const reducedMotion = window.matchMedia("(prefers-reduced-motion)"),
                    scrollTime = reducedMotion.matches ? 0 : 900;

                if (scrollTarget && document.getElementById(scrollTarget)) {
                    const el = document.getElementById(scrollTarget);

                    this.$smoothScroll({
                        duration: scrollTime,
                        offset: -100,
                        updateHistory: false,
                        scrollTo: el,
                    });

                    setTimeout(() => {
                        el.setAttribute("tabindex", -1);
                        el.setAttribute("style", "border: 0; -moz-outline-style: none; outline: none;");
                        setTimeout(() => {
                            el.focus();
                        }, 0);
                    }, scrollTime + 10);
                }
            }, 300);
        },
    },
};
</script>
