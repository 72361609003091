<template>
    <div
        id="app"
        data-server-rendered="true"
        :class="[{ 'hide-focus-children': !lastInteractionKey }, { 'high-c': highContrast }]"
        @mousedown="mouseInteract"
        @keydown="keyInteract"
    >
        <router-view v-if="mounted" />
    </div>
</template>

<script>
import { getLSItem } from "@/js/helpers/localstorage";
import { mapFields } from "vuex-map-fields";

export default {
    name: "App",
    data() {
        return {
            mounted: false,
            // isIntercomEnabled: true,
        };
    },
    computed: {
        ...mapFields(["highContrast", "lastInteractionKey"]),
        // reducedMotion() {
        //     return this.$store.state.reducedMotion;
        // },
    },
    mounted() {
        this.mounted = true;
        const stored_contrast_pref = getLSItem("high-contrast");
        //     stored_motion_pref = getLSItem("reduced-motion");
        if (stored_contrast_pref || stored_contrast_pref === false) this.highContrast = stored_contrast_pref;
        // if (stored_motion_pref || stored_motion_pref === false) this.$store.commit("SET_REDUCED_MOTION", stored_motion_pref);
    },
    methods: {
        mouseInteract() {
            this.lastInteractionKey = false;
        },
        keyInteract() {
            this.lastInteractionKey = true;
        },
    },
};
</script>
