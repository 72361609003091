<template>
    <span :class="`toggle ${heightWidthClasses} ${isOpen ? '' : 'closed'}`"></span>
</template>

<script>
export default {
    name: "PlusMinusToggle",
    props: {
        isOpen: Boolean,
        heightWidthClasses: {
            type: String,
            default: "h-4 w-4",
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
/* plus/minus icons */
.toggle {
    display: block;
    position: relative;
}

.toggle:before,
.toggle:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.6s ease-in-out;
}

.toggle:after {
    opacity: 0;
}

.closed:before {
    transform: translate(-50%, -50%) rotate(-180deg);
}

.closed:after {
    transform: translate(-50%, -50%) rotate(-90deg);
    opacity: 1;
}
</style>
