import { createApp } from "vue";

import App from "./App.vue";
import { toastSwal, vueUseSwal } from "./plugins/useSwal";
import UseDayJS from "./plugins/useDayJS.js";
import VueSmoothScroll from "vue3-smooth-scroll";

import PortalPlugin from "./plugins/portalPlugin.js";
import { authPlugin, vueInstallAuthPlugin } from "./plugins/auth.js";
import { apiPlugin, vueInstallApiPlugin } from "./plugins/api.js";

import { createRouter, createWebHistory } from "vue-router";
import routes from "./portal-routes.js";
import configs from "./portal-configs.js";
import store from "./store.js";
import axios from "axios";
// defineAsyncComponent (for later)

import(`./assets/css/${process.env.VUE_APP_ORG}/index.css`);

const router = createRouter({
    history: createWebHistory(),
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    scrollBehavior(to, from) {
        if (to.params.savedPosition) return {};
        return { left: 0, top: 0 };
    },
    routes: routes.getRoutes({
        config: configs[process.env.VUE_APP_ORG],
    }),
});

const app = createApp(App);

app.use(PortalPlugin, process.env.VUE_APP_ORG);
app.use(UseDayJS);
app.use(VueSmoothScroll);
app.use(vueUseSwal);
app.use(vueInstallApiPlugin);
app.use(vueInstallAuthPlugin);

app.use(router);
app.use(store);
app.mount("#app");

// ------------------------------------ Axios start ------------------------------------
// Axios request interceptor (https://axios-http.com/docs/interceptors)
axios.interceptors.request.use(
    async (request) => {
        // Where the request is a relative path (starts with /)
        if (request.url[0] === "/") {
            if (window.__PRERENDER_INJECTED) {
                // We need a full url when pre-rendering, but it seems this may cause issues when in production
                request.url = `${process.env.VUE_APP_URL}${process.env.VUE_APP_API_PATH}${request.url}`;
            } else {
                // handle locales here when these are needed for the portals
                request.url = `${process.env.VUE_APP_API_PATH}${request.url}`;
            }
            request.headers.Client = "portal";
            const token = authPlugin.getToken();
            if (token) request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
axios.interceptors.response.use(
    (response) => {
        // update jwt if a new one is sent back - also handles login
        authPlugin.setToken(response.headers.token);
        return response;
    },
    (error) => {
        if (error.response) {
            const e_code = error.response.status;
            let msg = `Unrecognised response code ${error.response.status}`;

            // Use toast to show an error message
            if (e_code === 400) msg = msg = error.response.data.error ? error.response.data.error : "Server error unspecified";
            else if (e_code === 422) msg = "Invalid parameters sent to endpoint";
            else if (e_code === 429) msg = "Too many attempts. Please wait 1 minute and try again";
            else if (e_code === 405) "API end point does not exist";
            else if (e_code === 409 || e_code === 401 || e_code === 404) error.response.data.error;

            // Re-route user when necessary (Not allowed / not found)
            if (e_code === 401) {
                msg = error.response.data.error;
                authPlugin.destroyToken();
                router.push("/");
                // router.push("/login");
            } else if (e_code === 404) {
                router.push("/notfound");
            }

            toastSwal.fire({ title: msg });
        } else {
            toastSwal.fire({ title: "Network Error" });
        }
        return Promise.reject(error);
    }
);
// ------------------------------------ Axios end ------------------------------------
// ------------------------------------ Route guards start ---------------------------

router.beforeEach(async (to, from, next) => {
    document.title = `${to.meta.title ? to.meta.title : ""} | ${configs[process.env.VUE_APP_ORG].org_name}`;

    // set the query params of the route in the store
    ["study_key", "utm_source", "utm_medium", "utm_campaign", "utm_content", "id", "click"].forEach((prop) => {
        if (to.query[prop]) {
            store.dispatch("setQueryParameter", { key: prop, value: to.query[prop] });
        }
    });

    // if page has been reloaded, try to fetch a new JWT with the refresh cookie
    if (!authPlugin.loggedIn()) await apiPlugin.auth.token_refresh();

    // window.Intercom("update", {
    //     email: Vue.prototype.$auth.getUserEmail(),
    //     last_request_at: parseInt(new Date().getTime() / 1000, 10),
    // });

    // ------------------------------- route guards
    let forward_path = to.path,
        forward_query = to.query;

    // prevent access to 'requiresGuest' routes from logged in users
    if (to.meta.requiresGuest && authPlugin.loggedIn()) {
        if (to.query.redirect) forward_path = to.query.redirect;
        else forward_path = "/home";
    }
    // prevent access to 'requiresAuth' routes from logged out users
    else if (to.meta.requiresAuth && !authPlugin.loggedIn()) {
        toastSwal.fire({ title: "Please log in first" });
        forward_path = "/login";
    }

    // ------------------------------- maintain the locale on route change
    // if (from.params.locale && from.params.locale !== to.params.locale) {
    //     forward_path = `/${from.params.locale}${to.path}`;
    // }

    // If no change or route adjustment needed
    if (forward_path === to.path && forward_query === to.query) next();
    // If the route has changed & redirection is needed, we want to keep parameters intact
    else {
        const param_keys = Object.keys(to.query);
        for (let i = 0; i < param_keys.length; i++) {
            const this_param = param_keys[i];
            if (this_param !== "redirect") forward_query[this_param] = to.query[this_param];
        }

        // If we're redirecting to login, we'll want to re-redirect to the original destination after
        if (forward_path === "/login") forward_query.redirect = to.path;

        next({ path: forward_path, query: forward_query });
    }
});

// ------------------------------------ Route guards end -----------------------------
