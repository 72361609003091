import BaseModel from "./BaseModel";

export default class LinkModel extends BaseModel {
    static $createBetween(fromId, toId) {
        const fromField = this.foreignFields.from;
        const toField = this.foreignFields.to;
        return this.api().post(`/${this.access.create}/create/${this.entity}/${fromField}/${fromId}/${toField}/${toId}`);
    }

    static $deleteBetween(fromId, toId) {
        const fromField = this.foreignFields.from;
        const toField = this.foreignFields.to;
        return new Promise((resolve, reject) => {
            this.api()
                .delete(`/${this.access.delete}/delete/${this.entity}/${fromField}/${fromId}/${toField}/${toId}`)
                .then(({ response }) => {
                    this.delete(response.data.id);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
