export default {
    loading: "Loading...",
    invite: "Great - we've sent you an invitation",
    "check-inbox": "Check your inbox!",
    signup: "Sign up",
    loop: "We will keep you in the loop with relevant updates and account- related emails. You can unsubscribe at any time.",
    agreement: "By clicking 'Sign up' you agree to",
    tos: "our terms of service",
    and: "and",
    privacy: "our privacy policy.",
};
