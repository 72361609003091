import LinkModel from "./LinkModel";
import { Role, Permission } from "../models";

export default class RolePermission extends LinkModel {
    static entity = "role_permissions";

    static foreignFields = { from: "role", to: "permission" }; // Used by LinkModel

    static fields() {
        return {
            id: this.attr(null),
            role_id: this.attr(""),
            role: this.belongsTo(Role, "role_id"),
            permission_id: this.attr(""),
            permission: this.belongsTo(Permission, "permission_id"),
        };
    }
}
