import { Survey } from "../models";
import BaseModel from "./BaseModel";

export default class Response extends BaseModel {
    static entity = "responses";

    static fields() {
        return {
            id: this.attr(""),
            user: this.attr(""),
            survey_id: this.attr(null),
            survey: this.belongsTo(Survey, "survey_id"),
            data: this.attr(""),
            ask: this.attr(""),
            completed: this.attr(""),
        };
    }

    static get_user_response_for_survey(user_id, survey_id) {
        return this.api().get(`/responses/users/${user_id}/surveys/${survey_id}`);
    }
}
