import LinkModel from "./LinkModel";
import { User, Role } from "../models";

export default class UserRole extends LinkModel {
    static entity = "user_roles";

    static foreignFields = { from: "user", to: "role" }; // Used by LinkModel

    static fields() {
        return {
            // attributes
            id: this.attr(null),
            user_id: this.attr(""),
            role_id: this.attr(""),

            // m-1 relationships
            user: this.belongsTo(User, "user_id"),
            role: this.belongsTo(Role, "role_id"),
            // relationships again in plural form to facilitate M2M linking components
            users: this.belongsTo(User, "user_id"),
            roles: this.belongsTo(Role, "role_id"),
        };
    }

    // Renamed this so we can find where it's used in the code. In future, our enitity joining components will use the link entity only fetching the nested data with graphQL.
    static $readNested(by) {
        return this.api().get(`/z/user_roles/role/${by}`);
    }
}
