import { Model } from "@vuex-orm/core";

export default class BaseModel extends Model {
    static cacheTimeout = 0;

    static lastFetched = null;

    // 'Fetching' is helpful particularly for studies, conditions & condition-studies as these are used in the navigation,
    // so without this two requests to each of those are made almost simultaneously when on a page that also requires that data
    // TODO: should try to be removed and this issue should be solved on the main client
    static fetching = false;

    // default to everything requiring admin access
    static access = {
        create: "admin",
        read: "admin",
        update: "admin",
        delete: "admin",
    };

    static setLastFetched() {
        this.lastFetched = new Date();
    }
    static fetchAgain(cacheTimeout) {
        if (cacheTimeout == 0) return true; // special case - always fetch if cacheTimeout is 0
        return !this.fetching && (!this.lastFetched || new Date().getTime() - cacheTimeout > this.lastFetched);
    }

    /**
     * @param {Object} by - Can be a single value or an array of values. Default is to read all items.
     * @param {String} field - The field that is read by. Default is 'id' added on the backend if unspecified here.
     * @param {String} prefix - The URL prefix used for the backend route. Should match the class name for custom classes.
     * @param {Number} cacheTimeout - Caching parameter. Will not read again in cacheTimeout seconds. Default is 0, or, no caching.
     * @param {Object} vuexOrmAxiosOptions - Axios options: https://vuex-orm.github.io/plugin-axios/guide/configurations.html#available-options.
     */
    static async $read({ by = [], field = null, prefix = null, cacheTimeout = this.cacheTimeout, vuexOrmAxiosOptions = {} } = {}) {
        const timeout = process.env.NUXT_APP_CLIENT_TYPE === "admin" ? 0 : cacheTimeout;
        const urlPrefix = prefix == null ? `/${this.access.read}/read` : prefix;
        const values = Array.isArray(by) ? by : [].concat(by);
        if (this.fetchAgain(timeout)) {
            if (values.length === 0) this.fetching = true;
            const { response } = await this.api().get(`${urlPrefix}/${this.entity}`, { params: { by: values, field } }, vuexOrmAxiosOptions);
            if (values.length === 0) {
                this.fetching = false;
                this.setLastFetched();
            }
            return Array.isArray(by) ? response.data : response.data[0];
        } else {
            const items = this.query()
                .where((item) => values.includes(item[field]))
                .get();
            return Array.isArray(by) ? items : items[0];
        }
    }

    static async $readAll({ permissionLevel = null, cacheTimeout = this.cacheTimeout, vuexOrmAxiosOptions = {} } = {}) {
        return this.$readBy([], { permissionLevel, cacheTimeout, vuexOrmAxiosOptions });
    }

    /**
     * @param {Object} value - Can be a single value or an array of values. Default is [], or, read all items.
     * @param {String} field - The field that is read by. Default is 'id'.
     * @param {String} permissionLevel - If you want to receive back only items that a specific permission has access to
     * @param {String} prefix - The URL prefix used for the backend route. Should match the class name for custom classes.
     * @param {Number} cacheTimeout - Caching parameter. Will not read again in cacheTimeout seconds. Default is 0, or, no caching.
     * @param {Object} vuexOrmAxiosOptions - Axios options: https://vuex-orm.github.io/plugin-axios/guide/configurations.html#available-options.
     */
    static async $readBy(value = [], { field = "id", permissionLevel = null, cacheTimeout = this.cacheTimeout, vuexOrmAxiosOptions = {} } = {}) {
        const _cacheTimeout = process.env.NUXT_APP_CLIENT_TYPE === "admin" ? 0 : cacheTimeout;
        const _value = field !== "id" && !Array.isArray(value) ? [value] : value;

        let url = `/${this.entity}`;
        let queryParams = {};
        if (Array.isArray(_value)) {
            if (_value.length > 0) queryParams = { [field]: _value };
        } else {
            url = `${url}/${_value}`;
        }

        if (permissionLevel) queryParams.permission_level = permissionLevel;

        if (this.fetchAgain(_cacheTimeout)) {
            if (Array.isArray(_value) && _value.length === 0) this.fetching = true;
            const { response } = await this.api().get(url, { queryParams }, vuexOrmAxiosOptions);
            if (Array.isArray(_value) && _value.length === 0) {
                this.fetching = false;
                this.setLastFetched();
            }
            return response.data;
        } else {
            const itemsQuery = this.query().where((item) => _value.includes(item[field]));
            return Array.isArray(_value) ? itemsQuery.get() : itemsQuery.first();
        }
    }

    static $create(data, prefix = "/z") {
        return this.api().post(`${prefix}/${this.entity}`, { data });
    }

    static $update(id, data, prefix = "/z") {
        return this.api().put(`${prefix}/${this.entity}/${id}`, { data });
    }

    static $delete(id, prefix = "/z") {
        return this.api().delete(`${prefix}/${this.entity}/${id}`, {
            delete: id,
        });
    }
    static $create_v2(data) {
        return this.api().post(`/${this.entity}`, { data });
    }

    static $update_v2(id, data) {
        return this.api().put(`/${this.entity}/${id}`, { data });
    }

    static $delete_v2(id) {
        return this.api().delete(`/${this.entity}/${id}`, {
            delete: id,
        });
    }

    static $searchBy(value, field) {
        return this.api().get(`/z/${this.entity}/search`, {
            params: { [field]: value },
        });
    }
}
