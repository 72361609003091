<template>
    <div id="passwordLostInput" class="w-full relative holder">
        <transition name="portal-fade" mode="out-in">
            <div v-if="loading" key="loading" class="text-center w-full h-full flex items-center justify-center holder">
                <LoadingSpinner :diameterRem="4" />
                <p class="sr-only">{{ strings.loading }}</p>
            </div>
            <div v-else-if="completed" key="complete" class="text-center w-full h-full flex flex-col justify-center holder">
                <div class="bg-black w-1/3 h-px mx-auto my-3 sm:mb-6 lg:mb-8"></div>

                <div aria-live="polite" class="pt-2 w-full">
                    <div v-show="showAfterCompletedForAriaLive">
                        <p class="leading-tight">{{ strings["reset-sent"].replace("{email}", emailFromInput) }}</p>
                        <p class="text-base text-bolder pt-1 leading-tight">
                            {{ strings["check-inbox"] }}
                        </p>
                    </div>
                </div>

                <emailClientLinks />
            </div>
            <div v-else key="initial" class="holder w-full h-full relative">
                <p>{{ strings.email }}</p>
                <div class="px-4 sm:px-12 pt-4 max-w-sm mx-auto">
                    <EmailInput buttonText="Request reset" @valid-email-input="sendResetLink" cypressLabel="password-lost" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/ui/loadingSpinner";
import EmailClientLinks from "@/components/auth/emailClientLinks";
import EmailInput from "@/components/auth/emailInput";
import text from "./passwordLostBoxText";

export default {
    name: "PasswordLostBox",
    components: {
        LoadingSpinner,
        EmailClientLinks,
        EmailInput,
    },
    props: {
        // startingEmail (when logged in - implement later)
    },
    data() {
        return {
            loading: false,
            completed: false,
            showAfterCompletedForAriaLive: false,
            emailFromInput: null,
            strings: text,
        };
    },
    methods: {
        sendResetLink(validEmailValue) {
            this.loading = true;
            console.log(validEmailValue);
            this.emailFromInput = validEmailValue;
            this.$api.auth
                .reset(validEmailValue)
                .then(() => {
                    this.loading = false;
                    this.completed = true;
                    this.handleFocusAria();
                })
                .catch(() => {
                    this.$toastSwal.fire({
                        title: "There was a problem with the email input.",
                    });
                    this.loading = false;
                });

            this.$emit("sent");
        },
        handleFocusAria() {
            // Show the completion text after the complete section is shown so that the aria-live
            // section picks up on this change. Then focus this section.
            setTimeout(() => {
                this.showAfterCompletedForAriaLive = true;
                if (this.$refs.first_email_link) this.$refs.first_email_link.focus();
            }, 0);
        },
    },
};
</script>

<style scoped>
.holder {
    min-height: 224px;
}

@media (max-width: 575px) {
    .holder {
        min-height: 300px;
    }
}
</style>
