import BaseModel from "./BaseModel";
// import { Condition, BlogpostCondition } from "../models";

export default class Blogpost extends BaseModel {
    static entity = "blogposts";

    static access = {
        create: "admin",
        read: "public",
        update: "admin",
        delete: "admin",
    };

    // only fetched every day
    static cacheTimeout = 86400000;

    static fields() {
        return {
            // attributes
            id: this.attr(null),
            created_at: this.attr(""),
            updated_at: this.attr(""),
            key: this.attr(""),
            title: this.attr(""),
            subtitle: this.attr(""),
            author: this.attr(""),
            date_published: this.attr(""),
            image_thumbnail_400w_300h: this.attr(""),
            image_hero_620w: this.attr(""),
            social_share_text: this.attr(""),
            tags: this.attr(""),
            related_blogposts: this.attr(""),
            route_name: this.attr(""),
            content: this.attr(""),
            published: this.attr(""),
            is_user_blog: this.attr(""),
            is_b2b_blog: this.attr(""),
            is_press_blog: this.attr(""),
            // references to the blogpost content eg. [1] Ref to content
            references: this.attr(""),
            // conditions: this.belongsToMany(Condition, BlogpostCondition, "blogpost_id", "condition_id"),
        };
    }

    static read_blogposts_for_condition(condition_id) {
        return this.api().get(`/blogposts/condition/${condition_id}`);
    }
    static publish(blogpost_id) {
        return this.api().post(`/blogposts/${blogpost_id}/publish`);
    }
    static unpublish(blogpost_id) {
        return this.api().post(`/blogposts/${blogpost_id}/unpublish`);
    }
}
