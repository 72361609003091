import BaseModel from "./BaseModel";
import { Response } from "../models";
// Condition, Study, StudySurvey

export default class Survey extends BaseModel {
    static entity = "surveys";

    static access = {
        create: "admin",
        read: "public",
        update: "admin",
        delete: "admin",
    };

    static BASIC_PROFILE_ID = "Ji2tsd88cLUdEM45Y09zba";
    static LONG_COVID_GOLD_ID = "qWPAFbfQdAV3unoAvKbNzg";
    static LRRK2_ID = "j46qesxrdQuj7J5z4kwgmE";

    // only fetched every day
    static cacheTimeout = 86400000;

    static fields() {
        return {
            // attributes
            id: this.attr(null),
            created_at: this.attr(""),
            updated_at: this.attr(""),
            title: this.attr(""),
            description: this.attr(""),
            definition: this.attr(""),
            display_results: this.attr(""),
            non_repeatable: this.attr(""),
            no_longer_active: this.attr(""),
            no_longer_active_exp: this.attr(""),
            may_never_be_shared: this.attr(""),
            is_pulse: this.attr(""),
            published: this.attr(""),
            // m-1 relationships
            response: this.hasOne(Response, "survey_id"),
            // condition: this.hasOne(Condition, "survey_id"),
            // m-m relationships
            // studies: this.belongsToMany(Study, StudySurvey, "survey_id", "study_id"),
        };
    }
}
