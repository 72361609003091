// Extracted from VuePress - https://github.com/vuejs/vuepress/blob/master/packages/%40vuepress/shared-utils/src/slugify.ts

// string.js slugify drops non ascii chars so we have to
// use a custom implementation here

// eslint-disable-next-line no-control-regex
const rControl = /[\u0000-\u001f]/g;
const rSpecial = /[\s~`!@#$%^&*()\-_+=[\]{}|\\;:"'“”‘’–—<>,.?/]+/g;
const rCombining = /[\u0300-\u036F]/g;

export const slugify = (str) => {
    // Split accented characters into components
    return (
        str
            .normalize("NFKD")
            // Remove accents
            .replace(rCombining, "")
            // Remove control characters
            .replace(rControl, "")
            // Replace special characters
            .replace(rSpecial, "-")
            // Remove continuous separators
            .replace(/\-{2,}/g, "-")
            // Remove prefixing and trailing separators
            .replace(/^\-+|\-+$/g, "")
            // ensure it doesn't start with a number (#121)
            .replace(/^(\d)/, "_$1")
            // lowercase
            .toLowerCase()
    );
};
