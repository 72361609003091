<template>
    <div :class="`my-16 max-w-2xl mx-auto relative ${link ? 'overflow-hidden' : ''}`" :style="`max-width: ${maxwitdh}px;`">
        <LazyLoadingImage :mainSrc="src" :imageHWRatio="ratio" :altDescription="alt" />
        <a
            v-if="link"
            :href="link"
            target="_blank"
            rel="nofollow noopener noreferrer"
            class="absolute z-10"
            style="left: 2px; top: 2px; right: 2px; bottom: 2px;"
            :aria_label="alt"
        ></a>
    </div>
</template>

<script>
import LazyLoadingImage from "@/components/ui/lazyLoadingImage";

export default {
    components: {
        LazyLoadingImage,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            default: "",
        },
        maxwitdh: {
            type: String,
            default: "",
        },
        link: {
            type: String,
            default: "",
        },
        ratio: {
            type: [String, Number],
            default: 0.333,
        },
    },
};
</script>
