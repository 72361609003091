<template>
    <div v-if="nodeContent">
        <div v-for="(content, index) in nodeContent" :key="`rich-text-html-${nodeIdentifier}-content-${index}`">
            <div v-html="content.text"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HtmlContent",
    props: {
        nodeContent: {
            type: Array,
            required: true,
        },
        nodeIdentifier: {
            type: String,
            required: true,
        },
    },
};
</script>
