import { createStore } from "vuex";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
// We're using the models from the client folder - these are shared between client, portal, admin/metric etc.
// This means that $store.state.entities is auto-populated with our structure of studies, reports etc.
import { database } from "@/data/models";

VuexORM.use(VuexORMAxios, { axios });

export default createStore({
    plugins: [VuexORM.install(database)],
    state: {
        mobileMenuHidden: true,
        highContrast: false,
        lastInteractionKey: false,
        reducedMotion: false,
        resizeCount: 0,
        joinedEmailList: null,
        queryParameters: {},
        welcomeSurveyId: "WrmZjFEgHCJDHkkonMnYK9",
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_QUERY_PARAMETER: (state, { key, value }) => {
            state.queryParameters[key] = value;
        },
        // TOGGLE_MOBILE_MENU_HIDDEN: (state) => (state.mobileMenuHidden = !state.mobile_menu_hidden),
        // SET_MOBILE_MENU_HIDDEN: (state) => (state.mobileMenuHidden = true),
        // SET_REDUCED_MOTION: (state, value) => (state.reducedMotion = value),
    },
    actions: {
        setQueryParameter: ({ commit }, { key, value }) => commit("SET_QUERY_PARAMETER", { key, value }),
    },
});
