<template>
    <div class="portal-carousel relative" :class="{ 'no-transition': noTransition }">
        <!-- {{ okToWrap }} {{ currentSlide }} {{ positions }} -->
        <!-- {{ stopTransitions }} {{ transitionEase }} {{ slides }} {{ wrapPositions }} {{ hiddenSlide }} -->
        <!-- prev button - can either be shown visually or appear only for keyboard users -->
        <div v-if="totalSlides > perPageAtCurrentWidth" class="prev-next-holder" :class="{ fixed: fixedPrevNext }">
            <div class="w-full max-w-3xl mx-auto relative h-0">
                <button
                    :aria-label="strings.prev"
                    :disabled="!allowPrev"
                    data-cy="carousel-prev"
                    ref="carousel-prev"
                    @click="clickArrowButton(-1)"
                    :class="{ 'hide-except-focus': !showPrevNext }"
                >
                    <div
                        class="absolute top-0 left-0 w-full h-full bg-background rounded-full border border-light-grey"
                        :class="{ 'border-mid-grey': allowPrev }"
                    ></div>
                    <div :class="{ 'opacity-25': !allowPrev }" class="trans-opacity">
                        <div class="direction-arrow h-4 w-4 border-b-2 border-l-2 ml-5 xs:ml-2 relative z-10" style="transform: rotate(45deg)"></div>
                    </div>
                </button>
            </div>
        </div>

        <!-- slides & content -->
        <div
            class="slides-holder relative"
            :class="[{ 'opacity-0': fadeOutSlides }, { 'px-4': showPrevNext }, { 'no-slide': noSlide }]"
            :style="`min-height: ${minHeight}px; transition-duration:  ${transitionTimeMs / 2500}s;`"
        >
            <div
                v-for="index in totalSlides"
                :key="`${name}-slide-${index - 1}`"
                :ref="`${name}-slide-${index - 1}`"
                class="absolute top-0 left-0 slide"
                :class="[
                    { current: index - 1 >= currentSlide && index - 1 < currentSlide + perPageAtCurrentWidth },
                    { 'px-4': perPageAtCurrentWidth > 1 || showPrevNext },
                ]"
                :style="`width: ${100 / perPageAtCurrentWidth}%;
                    min-height: ${minHeight}px;
                    transition-duration: ${hiddenSlide === index - 1 ? 0 : slideTransitionTimeSeconds}s;
                    transition-timing-function: ${transitionEase};
                    transform: translateX(${positions[index - 1].x}%);
                    opacity: ${positions[index - 1].opacity}`"
            >
                <transition name="caro-fade">
                    <div class="relative" v-show="positions[index - 1].show || showAll" :style="`min-height: ${minHeight}px;`">
                        <slot :name="`slot-${index - 1}`"></slot>
                    </div>
                </transition>
            </div>
        </div>

        <!-- next button - can either be shown visually or appear only for keyboard users -->
        <div v-if="totalSlides > perPageAtCurrentWidth" class="prev-next-holder" :class="{ fixed: fixedPrevNext }">
            <div class="w-full max-w-3xl mx-auto relative justify-end flex h-0">
                <button
                    :disabled="!allowNext"
                    :aria-label="`${strings.next} ${parentDisallowsNext || !allowNext ? strings.disabled : ''}`"
                    data-cy="carousel-next"
                    ref="carousel-next"
                    @click="clickArrowButton(1)"
                    :class="{ 'hide-except-focus': !showPrevNext }"
                >
                    <div
                        class="absolute top-0 left-0 w-full h-full bg-background rounded-full border border-light-grey"
                        :class="{ 'border-mid-grey': allowNext && !parentDisallowsNext }"
                    ></div>
                    <div :class="{ 'opacity-25': !allowNext || parentDisallowsNext }" class="trans-opacity">
                        <div
                            class="direction-arrow h-4 w-4 border-t-2 border-r-2 -ml-4 xs:-ml-2 relative z-10"
                            style="transform: rotate(45deg)"
                        ></div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import text from "./carouselText";

export default {
    name: "Carousel",
    props: {
        totalSlides: {
            type: Number,
            required: true,
        },
        // Ensure that refs and keys are unique even if multiple carousels are on a page
        name: {
            type: String,
            required: true,
        },
        // Allow custom / page specifically designed controls to send instructions to the slider
        updateCurrentSlideTo: {
            type: Number,
            required: false,
        },
        // How many slides show show across the screen at different screen widths
        perPageAtWidths: {
            type: Object,
            default: () => {
                return { 0: 1, 576: 1, 768: 1, 992: 1 };
            },
        },
        // Act like tabs - no slide movement
        noSlide: Boolean,
        // Do / do not allow 'wrapping' - (next goes back to 0 after last slide)
        noInfiniteRotate: Boolean,
        // Do / do not show the prev / next buttons while they are not being focused
        showPrevNext: Boolean,
        // For example with the full page surveyslider, we might want the prev/next buttons to be fixed, not absolute
        fixedPrevNext: Boolean,
        // Don't equalise the heights of all the slides
        allowDifferentHeights: Boolean,
        // If, for example, slides require validation or certain slides may sometimes be skipped,
        // previous / next requests can be passed to the parent for handling. The parent can then use
        // the 'updateCurrentSlideTo' parameter to effect whatever change is needed
        emitPrevNextRequests: Boolean,
        // Used for the surveyslider where a page must be valid
        parentDisallowsNext: Boolean,
    },
    data() {
        return {
            // Used to ensure slides are all the same height as the tallest one
            minHeight: 200,
            // Allow different numbers of slides to appear on screen at different widths
            perPageAtCurrentWidth: 1,
            // An array that can be re-arranged to provide a key for the position each slide should be in
            slides: Array.from(Array(this.totalSlides).keys()),
            // The pop-shift slide should not transition across the screen like the others
            hiddenSlide: -1,
            currentSlide: 0,
            transitionTimeMs: 900,
            // jumpingSlides is true (and other navigation prevented) when moving from e.g. slide 0 to slide 6
            jumpingSlides: false,
            // During a jump we need to fade out all the slides for a smooth transition
            fadeOutSlides: false,
            // During a jump we may need to move slides without transition once they are hidden
            stopTransitions: false,
            transitionEase: "ease-in-out",
            // For height checks
            showAll: false,
            // Used for testing - apply no transitions
            noTransition: false,
            strings: text,
        };
    },
    watch: {
        resizeCount() {
            this.getHeights();
            this.getNewHeight();
            this.getPerPageAtNewWidth();
        },
        updateCurrentSlideTo(val) {
            if (val !== null && val !== this.currentSlide && !this.jumpingSlides) {
                const diff = val - this.currentSlide,
                    direction = diff > 0 ? 1 : -1,
                    steps = diff > 0 ? diff : diff * -1;
                // console.log(`update ${this.currentSlide} to ${val} (${direction * steps} steps)`);

                if (this.noSlide) {
                    // If there's no slide transition, we can jump directly to the right slide
                    this.stopTransitionsAndStep(direction, steps);
                } else {
                    // Otherwise we want to animate in stages
                    // Which direction should we be going, and how many steps to get there?
                    if (steps === 1) this.takeStep(direction);
                    else this.jumpToSlide(direction, steps);
                }
            }
        },
    },
    mounted() {
        this.getPerPageAtNewWidth();
        // Once built, getHeights should work correctly as soon as mounted() is called
        this.getHeights();
        this.getNewHeight();
        // However, while developing, we have a flash of unstyled content and need a backup check after this is resolved
        // TODO: (but lower priority as only a dev irritation - prevent FOUC)
        setTimeout(() => {
            if (this.getHeights !== undefined) this.getHeights();
            if (this.getNewHeight !== undefined) this.getNewHeight();
        }, 500);

        // Used for testing
        if (this.$route.query.noTransition) {
            this.noTransition = true;
            this.transitionTimeMs = 0;
        }
    },
    computed: {
        ...mapFields(["lastInteractionKey"]),
        slideTransitionTimeSeconds() {
            if (this.stopTransitions || this.noTransition) return 0;
            if (this.jumpingSlides) return this.transitionTimeMs / 1500;
            return this.transitionTimeMs / 1000;
        },
        okToWrap() {
            // If we are allowing wrap (no noInfiniteRotate prop plus we have enough slides) we'll use different positioning than otherwise
            return !this.noInfiniteRotate && this.totalSlides > this.perPageAtCurrentWidth + 2;
        },
        wrapPositions() {
            // A non-changing set of positions/opacity for each slide if wrapping is allowed
            if (!this.okToWrap) return [];
            return Array.from(Array(this.totalSlides).keys()).map((position) => {
                if (position === 0) {
                    return { x: 0, opacity: 1, show: true };
                } else if (position > this.perPageAtCurrentWidth) {
                    // offscreen previous slides position (offscreen left)
                    return { x: -100, opacity: 0, show: false };
                } else if (position >= this.perPageAtCurrentWidth) {
                    // offscreen next slide position (offscreen right)
                    return { x: this.perPageAtCurrentWidth * 100, opacity: 0, show: false };
                } else if (position > 0 && position < this.perPageAtCurrentWidth) {
                    // other onscreen slides (not current slide)
                    return { x: position * 100, opacity: 1, show: true };
                }
            });
        },
        positions() {
            // A changing set of positions/opacity for each slide whether wrapping or not
            return Array.from(Array(this.totalSlides).keys()).map((index) => {
                if (this.okToWrap) {
                    return this.wrapPositions[this.slides.indexOf(index)];
                } else {
                    const onScreen = index >= this.currentSlide && index < this.currentSlide + this.perPageAtCurrentWidth;
                    return {
                        x: 100 * (index - this.currentSlide),
                        opacity: onScreen ? 1 : 0,
                        show: onScreen,
                    };
                }
            });
        },
        allowNext() {
            // Already moving between slides
            if (this.jumpingSlides) return false;
            // Allow if either this is not the last slide, or slides are allowed to wrap
            else if (this.okToWrap) return true;
            return this.currentSlide + this.perPageAtCurrentWidth < this.totalSlides;
        },
        allowPrev() {
            if (this.jumpingSlides) return false;
            else if (this.okToWrap) return true;
            return this.currentSlide > 0;
        },
        resizeCount() {
            return this.$store.state.resizeCount;
        },
    },
    methods: {
        clickArrowButton(direction) {
            // Either take the step as requested, or if validation is needed from a parent, emit the request
            if (this.emitPrevNextRequests) this.$emit("prev-next-request", direction);
            else if (!this.parentDisallowsNext) this.takeStep(direction);
        },
        nextSlide() {
            if (this.okToWrap) {
                this.hiddenSlide = this.slides[this.perPageAtCurrentWidth + 1];
                const popShiftSlide = this.slides.shift();
                this.slides.push(popShiftSlide);
                this.currentSlide = this.slides[0];
            } else {
                this.currentSlide += 1;
            }
            this.focusButton("carousel-prev");
        },
        prevSlide() {
            if (this.okToWrap) {
                this.hiddenSlide = this.slides[this.perPageAtCurrentWidth];
                const popShiftSlide = this.slides.pop();
                this.slides.unshift(popShiftSlide);
                this.currentSlide = this.slides[0];
            } else {
                this.currentSlide -= 1;
            }
            this.focusButton("carousel-next");
        },
        focusButton(buttonRef) {
            if (this.lastInteractionKey && this.showPrevNext) {
                const expectedTransitionDuration = this.jumpingSlides ? this.transitionTimeMs * 1.75 : this.transitionTimeMs;
                setTimeout(() => {
                    if (this.$refs[buttonRef]) this.$refs[buttonRef].focus();
                }, expectedTransitionDuration);
            }
        },
        takeStep(direction) {
            if (direction === 1) this.nextSlide();
            if (direction === -1) this.prevSlide();
            this.getNewHeight();
            this.$emit("current-slide-change-to", this.currentSlide);
        },
        jumpToSlide(direction, steps) {
            // Start the transition with a standard slow slide out (disallow navigation while we have timeouts occuring)
            this.jumpingSlides = true;
            this.fadeOutSlides = true;
            this.transitionEase = "ease-in";
            this.takeStep(direction);

            setTimeout(() => {
                // Jump to almost where we need to be (unless we're there already.) [Ensure context is still correct first.]
                if (this.endJump !== undefined && this.stopTransitionsAndStep !== undefined) {
                    if (steps > 2) {
                        this.stopTransitionsAndStep(direction, steps - 2);

                        setTimeout(() => {
                            // Allow time for stopTransitionsAndStep to complete, and then call endJump
                            if (this.endJump !== undefined && this.stopTransitions !== undefined) {
                                this.stopTransitions = false;
                                this.endJump(direction);
                            }
                        }, this.transitionTimeMs * 0.1);
                    } else {
                        this.endJump(direction);
                    }
                }
            }, this.transitionTimeMs * 0.35);
        },
        stopTransitionsAndStep(direction, steps) {
            this.stopTransitions = true;
            setTimeout(() => {
                // Step through to the last-but-one position ready to animate back in
                for (let s = 0; s < steps; s++) {
                    this.takeStep(direction);
                }
            }, 0);
        },
        endJump(direction) {
            // Animate in at single step speed again while opacity returns to normal
            this.transitionEase = "ease-out";
            setTimeout(() => {
                this.takeStep(direction);

                setTimeout(() => {
                    // Fade up slides
                    if (this.fadeOutSlides !== undefined) this.fadeOutSlides = false;
                }, this.transitionTimeMs * 0.2);

                setTimeout(() => {
                    // Re-allow navigation
                    if (this.jumpingSlides !== undefined && this.transitionEase !== undefined) {
                        this.jumpingSlides = false;
                        this.transitionEase = "ease-in-out";
                    }
                }, this.transitionTimeMs);
            }, 0);
        },
        getNewHeight() {
            // We'll either want to get the height of a slide when it becomes current (if the heights are not matched)
            // more usually the case if there is only ever one on-screen at a time
            if (this.allowDifferentHeights) {
                setTimeout(() => {
                    this.minHeight = 200;
                    this.$nextTick(() => {
                        const currentSlideEl = this.$refs[`${this.name}-slide-${this.currentSlide}`];
                        if (currentSlideEl && currentSlideEl.clientHeight) this.minHeight = currentSlideEl.clientHeight;
                    });
                }, this.slideTransitionTimeSeconds * 450);
            }
        },
        getHeights() {
            // Or on mount & on screen-resize, find the largest slide to make sure they have matching heights
            if (!this.allowDifferentHeights) {
                this.showAll = true;
                this.minHeight = 200;

                this.$nextTick(() => {
                    let tempMinHeight = 200;

                    for (let i = 0; i < this.slides.length; i += 1) {
                        const thisContent = this.$refs[`${this.name}-slide-${i}`];
                        if (thisContent && thisContent.clientHeight) {
                            // console.log(thisContent.clientHeight);
                            if (thisContent.clientHeight > tempMinHeight) tempMinHeight = thisContent.clientHeight;
                        }
                    }
                    this.minHeight = tempMinHeight;

                    setTimeout(() => {
                        this.showAll = false;
                    }, 0);
                });
            }
        },
        getPerPageAtNewWidth() {
            const previousPerPage = this.perPageAtCurrentWidth,
                w = window.innerWidth,
                categoriesSmallerThanW = Object.keys(this.perPageAtWidths).filter((wCategory) => wCategory <= w),
                thisWidthCategory = categoriesSmallerThanW[categoriesSmallerThanW.length - 1];
            this.perPageAtCurrentWidth = this.perPageAtWidths[thisWidthCategory];

            if (this.perPageAtCurrentWidth > previousPerPage && !this.okToWrap) this.checkCarouselIsInViablePosition();
        },
        checkCarouselIsInViablePosition() {
            // When going from a smaller to a bigger screen size, it's possible to end up with gaps
            // because you might have been on the last slide, and then gone to a size where there are 2 slides across
            // In this case, adjust the slide positions
            const lastSlideOnPage = this.currentSlide + this.perPageAtCurrentWidth;
            if (lastSlideOnPage > this.totalSlides) {
                // console.info("adjust for screen size change");
                this.stopTransitionsAndStep(-1, lastSlideOnPage - this.totalSlides);

                setTimeout(() => {
                    if (this.stopTransitions) this.stopTransitions = false;
                }, this.transitionTimeMs * 0.25);
            }
        },
    },
};
</script>

<style scoped>
/* We're setting transition time directly in styles so that this will always be in sync */
.slides-holder {
    transition-property: opacity;
    transition-timing-function: ease-in-out;
}

.slide {
    transition-property: transform, opacity;
}

/* tab style no-slide version - fade in & out */
.no-slide .slide {
    transform: translateX(0) !important;
    transition-property: opacity;
    transition-duration: 0.45s !important;
}

.no-slide .slide.current {
    transition-delay: 0.45s;
}

/* reduced motion - remove transitions */
.no-transition .slide,
.no-transition .slides-holder,
.no-transition .caro-fade-enter-active,
.no-transition .caro-fade-leave-active {
    transition-property: none;
}

@media (prefers-reduced-motion: reduce) {
    .slide {
        transition-property: none;
    }
}

.prev-next-holder {
    @apply z-20 absolute w-full;
    height: 0;
    top: 50%;
    transform: translateY(-50%);
    left: -2rem;
    width: calc(100% + 4rem);
}

.prev-next-holder.fixed {
    position: fixed;
    left: -1rem;
    width: calc(100% + 2rem);
}

@media (min-width: 1200px) {
    .prev-next-holder:not(.fixed) {
        left: -3.5rem;
        width: calc(100% + 7rem);
    }
}

@media (min-width: 1350px) {
    .prev-next-holder:not(.fixed) {
        left: -5rem;
        width: calc(100% + 10rem);
    }
}

@media (max-width: 449px) {
    .prev-next-holder {
        left: -3rem;
        width: calc(100% + 6rem);
    }

    .prev-next-holder.fixed {
        left: -1.5rem;
        width: calc(100% + 3rem);
    }
}

.prev-next-holder button {
    width: 4rem;
    height: 4rem;
    top: -2rem;
    @apply rounded-full flex items-center justify-center relative;
}

.hide-except-focus:not(:focus) {
    opacity: 0;
}

.caro-fade-enter-active {
    transition: opacity 0.45s ease-in-out 0.45s;
}

.caro-fade-leave-active {
    transition: opacity 0.45s ease-in-out;
}

.caro-fade-leave-to,
.caro-fade-enter,
.caro-fade-enter-from {
    opacity: 0;
}
</style>
