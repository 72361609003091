<template>
    <div id="passwordLost" class="bg-tertiary-b h-full min-h-screen relative">
        <div class="absolute left-0 w-full top-0 h-full bg-background opacity-slow" :class="{ 'opacity-0': !sent }"></div>
        <div id="render-element"></div>

        <div class="text-over-tertiary text-center h-full min-h-screen flex items-center justify-center px-4 pt-16 relative">
            <div class="max-w-lg mx-auto w-full">
                <h1 class="h1 display-text mb-4 lg:mb-8">{{ strings.reset }}</h1>
                <PasswordLostBox @sent="setSent" />
                <div class="mb-8 relative z-10 text-portal-sm sm:flex max-w-md mx-auto justify-center">
                    <router-link to="/login" data-cy="to-login" class="inline-block mx-1 border-b relative">{{ strings.remembered }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PasswordLostBox from "@/components/auth/passwordLostBox";
import text from "./passwordLostText";

export default {
    name: "PasswordLost",
    data() {
        return {
            email: null,
            strings: text,
            sent: false,
        };
    },
    components: {
        PasswordLostBox,
    },
    methods: {
        setSent() {
            this.sent = true;
        },
    },
};
</script>

<style scoped>
.opacity-slow {
    transition: opacity 0.9s ease-in-out;
}
</style>
