<template>
    <div class="min-h-screen w-full bg-tertiary-b text-over-tertiary flex items-center justify-center">
        <div class="max-w-md text-center">
            <h1 class="h1 mb-12 px-4">{{ strings.title }}</h1>
            <router-link :to="{ name: 'landing' }" class="btn bg-black text-white">{{ strings.home }}</router-link>
        </div>
    </div>
</template>

<script>
import text from "./notFoundText";

export default {
    name: "NotFound",
    data() {
        return {
            strings: text,
        }
    }
};
</script>
