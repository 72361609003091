export default {
    loading: "Loading...",
    login: "Log in",
    email: "Email",
    "password-required": "Your password is required",
    "password-short": "This looks too short to be a valid password",
    "email-required": "Your email is required",
    "email-invalid": "Please enter a valid email address",
    "forgot-password": "Forgot password",
    "no-acc": "No account yet?",
    signup: "Sign up",
};
