function decodeJWT(token) {
    const base64Url = token.split(".")[1],
        base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
}

// JWT Related utility functions
export const authPlugin = {
    jwt: null,

    setToken(token) {
        if (token) {
            this.jwt = token;
        }

        // TODO: this cypress functionality isn't in admin... does it need to be?
        if (localStorage.getItem("cy-authToken")) {
            this.jwt = localStorage.getItem("cy-authToken");
        }
    },

    destroyToken() {
        // TODO: this cypress functionality isn't in admin... does it need to be?
        // cypress delete the auth token if a jwt token exists
        if (this.jwt) {
            localStorage.removeItem("cy-authToken");
        }
        this.jwt = null;
    },

    getDecodedToken() {
        if (this.jwt) {
            const dt = decodeJWT(this.jwt),
                expiration = dt.exp,
                now = Date.now() / 1000;
            if (now < expiration) {
                return dt;
            }
        }
        this.destroyToken();
        return null;
    },

    getToken() {
        if (this.jwt) {
            const dt = decodeJWT(this.jwt),
                expiration = dt.exp,
                now = Date.now() / 1000;
            if (now < expiration) {
                return this.jwt;
            }
        }
        this.destroyToken();
        return null;
    },

    getUser() {
        const dt = this.getDecodedToken();
        if (dt) return dt.user_id;
        return null;
    },

    getUserEmail() {
        const dt = this.getDecodedToken();
        if (dt) return dt.user_email;
        return null;
    },

    isAdmin() {
        const dt = this.getDecodedToken();
        if (dt) return dt.roles.includes("admin");
        return false;
    },

    isTeam() {
        const dt = this.getDecodedToken();
        if (dt) return dt.roles.includes("admin") || dt.roles.includes("team");
        return false;
    },

    // does the JWT have one of roles
    hasRole(roles) {
        const roleArray = Array.isArray(roles) ? [...roles] : [].concat(roles);
        roleArray.push("system-admin");
        const dt = this.getDecodedToken();
        // return true for a non-empty intersection
        if (dt && dt.roles.filter((v) => roleArray.includes(v)).length > 0) {
            return true;
        }
        return false;
    },

    // does the JWT have one of permissions
    hasPermission(permissions) {
        const permissionArray = Array.isArray(permissions) ? [...permissions] : [].concat(permissions);
        permissionArray.push("perm.superuser");
        const dt = this.getDecodedToken();
        // return true for a non-empty intersection
        if (dt && dt.permissions.filter((v) => permissionArray.includes(v)).length > 0) {
            return true;
        }
        return false;
    },

    getImpersonatorEmail() {
        const dt = this.getDecodedToken();
        if (dt) return dt.impersonator_email;
        return null;
    },

    loggedIn() {
        return !!this.getToken();
    },

    millisecondsTillExpiry() {
        const dt = this.getDecodedToken();
        if (dt) {
            const expiration = dt.exp,
                now = Date.now() / 1000;

            return (expiration - now) * 1000;
        }
        return false;
    },
};

export const vueInstallAuthPlugin = {
    install: (app) => {
        app.config.globalProperties.$auth = authPlugin;
    },
};
