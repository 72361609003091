<template>
    <div class="mt-6 sm:flex items-center justify-center w-full max-w-xs sm:max-w-lg mx-auto bg-seconday sm:mb-10">
        <div class="w-full sm:w-1/3 sm:pr-2 flex-shrink-0 mb-2 sm:mb-0">
            <a ref="first_email_link" href="https://outlook.live.com/mail/" class="mail-link xs:w-1/3 text-portal-sm">
                <!-- eslint-disable-next-line max-len --><!-- prettier-ignore -->
                <svg width="18" height="18" viewBox="0 0 22 22" fill="none" class="-ml-1 relative flex-shrink-0" style="right: 2px;"><rect x="0.5" y="0.5" width="21.2493" height="21.5" rx="1" fill="#0078D4" /><path fill-rule="evenodd" clip-rule="evenodd" d="M10.9995 17.1109C13.9593 17.1109 16.3588 15.4733 16.3588 11.3128C16.3588 7.15218 13.9593 5.51465 10.9995 5.51465C8.03959 5.51465 5.64014 7.34386 5.64014 11.3128C5.64014 15.4733 8.03959 17.1109 10.9995 17.1109ZM10.9993 15.105C12.7129 15.105 14.102 14.0339 14.102 11.3127C14.102 8.59144 12.7129 7.52041 10.9993 7.52041C9.28566 7.52041 7.8965 8.71681 7.8965 11.3127C7.8965 14.0339 9.28566 15.105 10.9993 15.105Z" fill="white" fill-opacity="1" /></svg>
                <span class="relative" style="left: 4px">{{ strings.outlook }}</span>
            </a>
        </div>
        <div class="w-full sm:w-1/3 sm:px-1 flex-shrink-0 mb-2 sm:mb-0">
            <a href="https://mail.google.com/mail/" class="mail-link xs:w-1/3 text-portal-sm">
                <!-- eslint-disable-next-line max-len --><!-- prettier-ignore -->
                <svg width="18" height="17" viewBox="0 0 23 18" fill="none" class="-ml-1 relative flex-shrink-0" style="right: 2px;"><rect x="0.831543" y="0.742676" width="22.1133" height="17.0074" rx="2" fill="#FFF5F3" /><path d="M22.7405 1.27846C22.3071 0.690808 21.4794 0.565729 20.8918 0.999089L11.9682 7.5797L2.94624 1.07086C2.35355 0.643271 1.52645 0.777109 1.09886 1.3698C0.671678 1.96192 0.804797 2.78814 1.39633 3.21613L11.961 10.8598L11.9611 10.8576L11.9615 10.8622L22.4606 3.12754C23.0486 2.69431 23.174 1.86631 22.7405 1.27846Z" fill="#D93025" /><path d="M20.3552 2.11976H23V15.7129C23 16.8175 22.1046 17.7129 21 17.7129H20.3552V2.11976Z" fill="#D93025" /><path d="M3.49462 2.15651H0.849844V15.7497C0.849844 16.8542 1.74527 17.7497 2.84984 17.7497H3.49462V2.15651Z" fill="#D93025" /></svg>
                <span class="relative" style="left: 4px">{{ strings.gmail }}</span>
            </a>
        </div>
        <div class="w-full sm:w-1/3 sm:pl-2 flex-shrink-0 mb-2 sm:mb-0">
            <a href="https://mail.yahoo.com" class="mail-link xs:w-1/3 text-portal-sm">
                <!-- eslint-disable-next-line max-len --><!-- prettier-ignore -->
                <svg width="18" height="18" viewBox="0 0 22 23" class="-ml-1 relative flex-shrink-0" style="right: 2px;"><rect x="0.5" y="0.75" width="21.5" height="21.5" fill="#7010EC" /><path fill-rule="evenodd" clip-rule="evenodd" d="M16.1588 3.57715L12.9276 11.6241L12.8422 11.8416H16.4695L19.8872 3.57715H16.1588ZM15.8481 14.7155C15.8481 15.8738 14.9092 16.8127 13.751 16.8127C12.5927 16.8127 11.6538 15.8738 11.6538 14.7155C11.6538 13.5573 12.5927 12.6183 13.751 12.6183C14.9092 12.6183 15.8481 13.5573 15.8481 14.7155ZM2.51939 7.02585L6.30985 15.9428L4.91173 19.205H8.29829L13.3315 6.77729H10.1314L7.92546 12.1523L5.93702 6.77729H2.41064L2.51939 7.02585Z" fill="white" /></svg>
                <span class="relative" style="left: 4px">{{ strings.yahoo }}</span>
            </a>
        </div>
    </div>
</template>

<script>
import text from "./emailClientLinksText";

export default {
    name: "EmailClientLinks",
    data() {
        return {
            strings: text,
        };
    },
};
</script>

<style scoped>
.mail-link {
    @apply relative border rounded-lg px-6 py-2 leading-tight inline-flex justify-center items-center w-full;
}
</style>
