*/ In the privacy & terms pages, unlike the rest of the site, there's a lot of text and so I have not extracted it in the same way as 
other components & pages - it seems worthwhile to have separate rules for these 2 pages in the Smartling dashboard */
<template>
    <div class="min-h-screen pt-32 max-w-xl px-4 mx-auto">
        <div id="render-element"></div>
        <h1 class="h1 mb-8 text-primary high-c-text-black">Privacy Policy</h1>
        <p class="mb-16">
            The Wellness League (a Fatty Liver Foundation initiative) and Sano Genetics Limited respect your privacy and are committed to protecting
            your personal data. This privacy notice will inform you as to how we look after your personal data when you visit our website, and tell
            you about your privacy rights and how the law protects you.
        </p>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">1. Important information and who we are</h2>
            <h3 class="text-regular text-lg mb-2">1.1 Purpose of this privacy notice</h3>
            <p class="mb-6">
                This privacy notice aims to give you information on how The Wellness League (a Fatty Liver Foundation initiative) and Sano Genetics
                Limited collect and process your personal data through your use of our website. Our website is not intended for children and we do not
                knowingly collect data relating to children, other than from their parents or legal guardians where they choose to give it to us. It
                is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on
                specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are
                using your data. This privacy notice supplements the other notices and is not intended to override them.
            </p>
            <h3 class="text-regular text-lg mb-2">1.2 Controllers</h3>
            <p class="mb-6">
                The Wellness League (a Fatty Liver Foundation initiative) and Sano Genetics Limited are the controllers and responsible for your
                personal data (collectively referred to as “The Wellness League (a Fatty Liver Foundation initiative) and Sano Genetics”, "we", "us"
                or "our" in this privacy notice). We have appointed a data privacy manager who is responsible for overseeing questions in relation to
                this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please
                contact the data privacy manager using the details set out below.
            </p>
            <h3 class="text-regular text-lg mb-2">1.3 Contact details</h3>
            <p class="mb-6">
                <span class="block pb-1">
                    Full name of legal entities: <span class="text-regular">The Fatty Liver Foundation, Sano Genetics Limited</span>
                </span>
                <span class="block pb-1"> Name or title of data privacy manager: <span class="text-regular">William Jones</span> </span>
                <span class="block pb-2"> Contact details: <span class="text-regular">will@sanogenetics.com</span> </span>
                You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for
                data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the
                ICO so please contact us in the first instance.
            </p>
            <h3 class="text-regular text-lg mb-2">1.4 Changes to the privacy notice and your duty to inform us of changes</h3>
            <p class="mb-6">
                This version was produced in January 2022. It is important that the personal data we hold about you is accurate and current. Please
                keep us informed if your personal data changes during your relationship with us.
            </p>
            <h3 class="text-regular text-lg mb-2">1.5 Third-party links</h3>
            <p>
                Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those
                connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not
                responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you
                visit.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">2. The data we collect about you</h2>
            <h3 class="text-regular text-lg mb-2">2.1 Personal data, or personal information</h3>
            <p class="mb-6">
                This means any information about an individual from which that person can be identified. It does not include data where the identity
                has been removed (anonymous data).
            </p>
            <h3 class="text-regular text-lg mb-2">2.2 On our website</h3>
            <p class="mb-6">We collect the personal data of users of our website.</p>
            <h3 class="text-regular text-lg mb-2">2.3 Collection, use, transfer and storage</h3>
            <p class="mb-4">
                We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:
            </p>
            <ul class="pl-2 md:pl-4">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.1</span> Identity Data includes first name, last name, username or similar identifier, date of
                    birth, place of birth and gender.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.2</span> Contact Data includes billing address, delivery address, email address and telephone
                    numbers.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.3</span> Financial Data includes bank account and payment card details.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.4</span> Transaction Data includes details about payments to and from you.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.5</span> Technical Data includes internet protocol (IP) address, your login data, browser type and
                    version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on
                    the devices you use to access this website.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.6</span> Profile Data includes your username and password, your feedback and survey responses.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.7</span> Usage Data includes information about how you use our website.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">2.3.8</span> Marketing and Communications Data includes your preferences in receiving marketing from us
                    and our third parties and your communication preferences.
                </li>
                <li class="pb-4 leading-snug">
                    <span class="text-regular">2.3.9</span> Special Category Data includes health, race, ethnicity, sexual orientation, and genetics
                    information. We would only collect this with your explicit consent.
                </li>
            </ul>
            <p class="mb-6">
                We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived
                from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity.
                For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if
                we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the
                combined data as personal data which will be used in accordance with this privacy notice.
            </p>
            <h3 class="text-regular text-lg mb-2">2.4 If you fail to provide personal data</h3>
            <p>
                Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data
                when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you
                with goods or services).
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-2">3. How is your personal data collected?</h2>
            <p class="mb-6">We use different methods to collect data from and about you including through:</p>
            <h3 class="text-regular text-lg mb-2">3.1 Direct interactions</h3>
            <p class="mb-4">
                You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or
                otherwise. This includes personal data you provide when you:
            </p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug"><span class="text-regular">3.1.1 </span>fill out a contact form.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">3.1.2 </span>create an account on our website.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">3.1.3 </span>request our newsletter to be sent to you.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">3.1.4 </span>give us some feedback.</li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">3.1.5 </span>fill out a survey or questionnaire from us, or one of our research partners.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">3.1.6 </span>upload your genetic data from a third party, or receive a genetic test from us.
                </li>
            </ul>
            <h3 class="text-regular text-lg mb-2">3.2 Automated technologies or interactions</h3>
            <p class="mb-6">
                As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We
                collect this personal data by using cookies and other similar technologies.
            </p>
            <h3 class="text-regular text-lg mb-3">3.3 Third parties or publicly available</h3>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug"><span class="text-regular">3.3.1 </span>Publicly available data from genealogy websites.</li>
                <li class="pb-1 leading-snug"><span class="text-regular">3.3.2 </span>Publicly available genetic data.</li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">3.3.3 </span>Information from health care providers (only with your explicit consent).
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">3.3.4 </span>Genetic data from third parties (only with your explicit consent).
                </li>
            </ul>
            <h3 class="text-regular text-lg mb-3">3.4 Technical Data from the following parties:</h3>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">3.4.1 </span>analytics providers such as Facebook and Google based outside the EU.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">3.4.2 </span>advertising networks such as Reddit, Facebook, and Google based outside the EU.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">3.4.3 </span>search information providers such as Google, Microsoft Bing, and DuckDuckGo based outside
                    the EU.
                </li>
            </ul>
            <h3 class="text-regular text-lg mb-3">3.5 Contact, Financial and Transaction Data</h3>
            <p>From providers of technical, payment and delivery services such as Stripe based outside the EU.</p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">4. How we use your personal data</h2>
            <h3 class="text-regular text-lg mb-3">4.1 We will only use your personal data when the law allows us to</h3>
            <p class="mb-4">Most commonly, we will use your personal data in the following circumstances:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">4.1.1 </span>Where we have your consent to using your personal data for a particular purpose.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">4.1.2 </span>Where we need to perform the contract we are about to enter into or have entered into with
                    you.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">4.1.3 </span>Where it is necessary for our legitimate interests and your interests and fundamental
                    rights do not override those interests.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">4.1.4 </span>Where we need to comply with a legal or regulatory obligation.
                </li>
            </ul>
            <h3 class="text-regular text-lg mb-3">4.2 Purposes for which we will use your personal data</h3>
            <p class="mb-4">
                We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal
                bases we rely upon to do so. We have also identified what our legitimate interests are where appropriate.
            </p>
            <p class="mb-6">
                Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using
                your data. Please contact us if you need details about the specific legal ground we are relying upon to process your personal data
                where more than one ground has been set out in the table below.
            </p>
            <div aria-hidden="true" class="hidden sm:flex text-bolder border-t border-mid-grey leading-tight">
                <div class="w-2/5 flex-shrink-0 bg-lightest-grey py-2 sm:py-4 px-1 flex items-end">Purpose/Activity</div>
                <div class="w-1/5 flex-shrink-0 py-2 sm:py-4 px-1 flex items-end">Type of data</div>
                <div class="w-2/5 flex-shrink-0 bg-lightest-grey py-2 sm:py-4 px-1">
                    Lawful basis for processing including basis of legitimate interest
                </div>
            </div>
            <ul class="leading-tight text-portal-sm lg:text-base">
                <li class="sm:flex border-b border-t border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To register you as a new customer
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Performance of a contract with you
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To carry out a genetic test that you have requested
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Performance of a contract with you
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To collect other information about you relevant to a research project in
                        which you have requested to participate
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Performance of a contract with you
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To process and deliver your order including: (a) Manage payments fees and
                        charges (b) Collect and recover money owed to us.
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact (c) Financial (d) Transaction (e) Marketing and
                        Communications
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Performance of a contract with you (b) Necessary for our
                        legitimate interests (to recover debts due to us)
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To manage our relationship with you which will include: (a) Notifying you
                        about changes to our terms or privacy policy (b) Asking you to leave a review or take a survey.
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact (c) Profile (d) Marketing and Communications
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>(a) Performance of a contract with you (b) Necessary to comply
                        with a legal obligation (c) Necessary for our legitimate interests (to keep our records updated and to study how customers use
                        our products/services)
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To administer and protect our business and this website (including
                        troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact (c) Technical
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>(a) Necessary for our legitimate interests (for running our
                        business, provision of administration and IT services, network security, to prevent fraud and in the context of a business
                        reorganisation or group restructuring exercise) (b) Necessary to comply with a legal obligation.
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To deliver relevant content and advertisements to you and measure or
                        understand the effectiveness of the advertising we serve to you.
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications
                        (f) Technical (g) Special Category Data
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Necessary for our legitimate interests (to study how customers
                        use our products/services, to develop them, to grow our business and to inform our marketing strategy)
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To use data analytics to improve Our website, marketing, customer
                        relationships and experiences.
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Technical (b) Usage
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Necessary for our legitimate interests (to define types of
                        customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our
                        marketing strategy)
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To make suggestions and recommendations to you about goods or services that
                        may be of interest to you.
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact (c) Technical (d) Usage (e) Profile (f) Special
                        Category Data
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Necessary for our legitimate interests (to develop our
                        products/services and grow our business)
                    </span>
                </li>
                <li class="sm:flex border-b border-mid-grey">
                    <span class="block sm:w-2/5 flex-shrink-0 bg-lightest-grey py-3 sm:py-4 px-1 text-regular">
                        <span class="sm-sr-only">Purpose/Activity: </span>To inform you of clinical research studies that you might want to
                        participate on based on your genetic testing results.
                    </span>
                    <span class="block sm:w-1/5 flex-shrink-0 pt-2 pb-1 sm:py-4 px-1">
                        <span class="sm-sr-only">Type of data: </span>(a) Identity (b) Contact (c) Special Category Data
                    </span>
                    <span class="block sm:w-2/5 flex-shrink-0 sm:bg-lightest-grey pb-4 sm:py-4 px-1">
                        <span class="sm-sr-only">Lawful basis for processing*: </span>Performance of a contract with you
                    </span>
                </li>
            </ul>
            <span class="block pt-2 sm:hidden">*including basis of legitimate interest</span>
            <h3 class="text-regular text-lg mb-3 mt-8">4.3 Marketing</h3>
            <p class="mb-6">
                We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We have
                established a privacy centre where you can view and make certain decisions about your personal data use when you are logged into our
                site: https://thewellnessleague.org/account
            </p>
            <h3 class="text-regular text-lg mb-3">4.4 Promotional offers from us</h3>
            <p class="mb-6">
                We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may
                be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing). You
                will receive marketing communications from us if you have requested information from us or purchased goods or services from us or if
                you provided us with your details when you entered a competition or registered for a promotion and, in each case, you have not opted
                out of receiving that marketing.
            </p>
            <h3 class="text-regular text-lg mb-3">4.5 Third-party marketing</h3>
            <p class="mb-6">We will get your express opt-in consent before we share your personal data with any company for marketing purposes.</p>
            <h3 class="text-regular text-lg mb-3">4.6 Opting out</h3>
            <p class="mb-6">You can ask us or third parties to stop sending you marketing messages by contacting us at any time.</p>
            <h3 class="text-regular text-lg mb-3">4.7 Change of purpose</h3>
            <p class="mb-6">
                We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it
                for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing
                for the new purpose is compatible with the original purpose, please contact us. If we need to use your personal data for an unrelated
                purpose, we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your
                personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">5. Disclosures of your personal data</h2>
            <h3 class="text-regular text-lg mb-3">5.1 Sharing your data</h3>
            <p class="mb-4">
                We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.
            </p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">5.1.1 </span>Third parties who are conducting legitimate research activities using anonymised and
                    de-identified genetic and health data.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">5.1.2 </span>Third parties to whom we may choose to sell, transfer, or merge parts of our business or
                    our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then
                    the new owners may use your personal data in the same way as set out in this privacy notice.
                </li>
            </ul>
            <h3 class="text-regular text-lg mb-3">5.2 Third parties</h3>
            <p class="mb-6">
                We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow
                our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data
                for specified purposes and in accordance with our instructions.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">6. International data transfers</h2>
            <h3 class="text-regular text-lg mb-3">6.1 We do not transfer your personal data outside the European Economic Area (EEA).</h3>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">7. Data security</h2>
            <h3 class="text-regular text-lg mb-3">7.1 Security Measures</h3>
            <p class="mb-6">
                We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an
                unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and
                other third parties who have a business need to know. They will only process your personal data on our instructions and they are
                subject to a duty of confidentiality.
            </p>
            <h3 class="text-regular text-lg mb-3">7.2 Suspected breaches</h3>
            <p class="mb-6">
                We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a
                breach where we are legally required to do so
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">8. Data retention</h2>
            <h3 class="text-regular text-lg mb-3">8.1 How long will you use my personal data for?</h3>
            <p class="mb-4">
                We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes
                of satisfying any legal, accounting, or reporting requirements.
            </p>
            <p class="mb-6">
                To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data,
                the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal
                data and whether we can achieve those purposes through other means, and the applicable legal requirements.
            </p>
        </section>
        <section class="py-12 lg:py-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">9. Your legal rights</h2>
            <h3 class="text-regular text-lg mb-3">9.1 Personal data rights</h3>
            <p class="mb-6">
                Under certain circumstances, you have rights under data protection laws in relation to your personal data. The various rights afforded
                to you are listed in paragraph 10.3. If you wish to exercise any of them, please contact us.
            </p>
            <h3 class="text-regular text-lg mb-3">9.2 No fee usually required</h3>
            <p class="mb-6">
                You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a
                reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request
                in these circumstances.
            </p>
            <h3 class="text-regular text-lg mb-3">9.3 What we may need from you</h3>
            <p class="mb-6">
                We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal
                data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person
                who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our
                response.
            </p>
            <h3 class="text-regular text-lg mb-3">9.4 Time limit to respond</h3>
            <p class="mb-6">
                We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is
                particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
            </p>
        </section>
        <section class="pt-12 lg:pt-16 border-t border-light-grey text-base text-lighter max-w-lg mx-auto">
            <h2 class="text-regular h3 mb-6">10. Glossary</h2>
            <h3 class="text-regular text-lg mb-3">10.1 Lawful Basis</h3>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.1.1 </span>Legitimate Interest means the interest of our business in conducting and managing our
                    business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and
                    balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our
                    legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you
                    (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we
                    assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.1.2 </span>Performance of Contract means processing your data where it is necessary for the
                    performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.1.3 </span>Comply with a legal or regulatory obligation means processing your personal data where it
                    is necessary for compliance with a legal or regulatory obligation that we are subject to.
                </li>
            </ul>
            <h3 class="text-regular text-lg mb-3">10.2 Third Parties</h3>
            <div class="pl-2 md:pl-4 mb-6 leading-snug">
                <span class="text-regular">10.2.1 </span>External Third Parties
                <span class="block pt-2">
                    <span class="text-regular">(a) </span>Professional advisers including lawyers, bankers, auditors and insurers.
                </span>
                <span class="block">
                    <span class="text-regular">(b) </span>HM Revenue &amp; Customs, regulators and other authorities who require reporting of
                    processing activities in certain circumstances.
                </span>
            </div>
            <h3 class="text-regular text-lg mb-3">10.3 Your Legal Rights</h3>
            <p class="mb-4">You have the right to:</p>
            <ul class="pl-2 md:pl-4 mb-6">
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.3.1 </span>Request access to your personal data (commonly known as a ‘data subject access request’).
                    This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.3.2 </span>Request correction of the personal data that we hold about you. This enables you to have
                    any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you
                    provide to us.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.3.3 </span>Request erasure of your personal data. This enables you to ask us to delete or remove
                    personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove
                    your personal data where you have successfully exercised your right to object to processing (see below), where we may have
                    processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note,
                    however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified
                    to you, if applicable, at the time of your request.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.3.4 </span>Object to processing of your personal data where we are relying on a legitimate interest
                    (or those of a third party) and there is something about your particular situation which makes you want to object to processing on
                    this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are
                    processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate
                    grounds to process your information which override your rights and freedoms.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.3.5 </span>Request restriction of processing of your personal data. This enables you to ask us to
                    suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b)
                    where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no
                    longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data
                    but we need to verify whether we have overriding legitimate grounds to use it.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.3.6 </span>Request the transfer of your personal data to you or to a third party. We will provide to
                    you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this
                    right only applies to automated information which you initially provided consent for us to use or where we used the information to
                    perform a contract with you.
                </li>
                <li class="pb-1 leading-snug">
                    <span class="text-regular">10.3.7 </span>Withdraw consent at any time where we are relying on consent to process your personal
                    data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw
                    your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the
                    time you withdraw your consent.
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
// import OnOffToggle from "@/components/ui/onOffToggle";
// import { getLSItem, setLSItem } from "@/js/helpers/localstorage";

export default {
    name: "Privacy",
    components: {
        // OnOffToggle,
    },
    data() {
        return {
            services: [],
        };
    },
    mounted() {
        // this.services = this.portalConfig.privacy_services.map((priv_service) => {
        //     return { ...priv_service, active: true };
        // });
        // const ls_privacy_services = getLSItem("privacy-services");
        // // set selected value of each service pref based on local storage
        // if (ls_privacy_services) {
        //     ls_privacy_services.forEach((ls_priv_item) => {
        //         const service_found = this.services.find((x) => x.service === ls_priv_item.service);
        //         if (service_found) service_found.active = ls_priv_item.active;
        //     });
        // }
    },
    methods: {
        // updateServicePrefs() {
        //     const privacy_services = this.services.map((x) => ({ service: x.service, active: x.active }));
        //     // console.log(privacy_services);
        //     setLSItem("privacy-services", privacy_services);
        //     // If logged in then set in LS and DB otherwise LS only (for later)
        //     // if (this.$auth.loggedIn()) {
        //     //     this.$api.user.update_user_field("privacy_services", privacy_services).then(() => {
        //     //         // reload page after DB commit to refresh choices
        //     //         this.$router.go();
        //     //     });
        //     // }
        //     // reload page after DB commit to refresh choices
        //     this.$router.go();
        // },
    },
};
</script>

<style scoped>
@media (min-width: 576px) {
    .sm-sr-only {
        @apply absolute opacity-0;
        left: -99999px;
    }
}
</style>
