<template>
    <div>
        <div class="portal-input">
            <label class="sr-only">{{ strings.email }}</label>
            <input
                v-model.trim="email"
                :placeholder="startingEmail"
                :data-cy="`${cypressLabel}-input`"
                class="input w-full rounded-full appearance-none px-6 py-4 high-c-border"
                type="email"
                name="email"
                spellcheck="false"
                autofocus
                @input="delayTouch(v$.email)"
                @keyup.enter="enterEmail"
            />
            <div class="focus-border"></div>
        </div>
        <button
            class="btn btn-larger bg-black text-white block w-full mt-4"
            :data-cy="`${cypressLabel}-button`"
            :class="{ 'opacity-90': v$.email.$invalid }"
            :disabled="v$.email.$invalid"
            @click="enterEmail"
        >
            {{ buttonText }}
        </button>
        <div class="text-portal-sm mb-2">
            <div class="text-sm leading-none text-lighter mt-3" style="min-height: 1.1rem" :class="{ 'opacity-0': !v$.email.$error }">
                <div v-for="error of v$.email.$errors" :key="`error-${error.$uid}`">
                    <span v-if="error.$validator === 'required'" :data-cy="`${cypressLabel}-missing`">{{ strings.required }}</span>
                    <span v-if="error.$validator === 'email'" :data-cy="`${cypressLabel}-invalid`">{{ strings.valid }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import debounce from "lodash.debounce";
import text from "./emailInputText";

export default {
    name: "EmailInput",
    props: {
        buttonText: {
            type: String,
            default: "Sign up",
        },
        startingEmail: {
            type: String,
            default: "user@email.com",
        },
        cypressLabel: {
            type: String,
            default: "email",
        },
    },
    setup() {
        // https://vuelidate-next.netlify.app/#getting-started-2
        return { v$: useVuelidate() };
    },
    data() {
        return {
            // Will have a purpose once this is used by logged in people
            email: this.startingEmail === "user@email.com" ? null : this.startingEmail,
            strings: text,
        };
    },
    validations: {
        // email validation in this case also checks for '<' and '>' characters
        email: {
            required,
            email,
        },
    },
    methods: {
        enterEmail() {
            if (this.v$.$invalid) {
                console.info("email does not meet validation criteria");
                this.v$.$touch();
                return;
            } else {
                // If the input is valid, we'll pass it up for handling
                this.$emit("valid-email-input", this.email);
            }
        },
        delayTouch: debounce((inputElement) => {
            if (!inputElement.$dirty) inputElement.$touch();
        }, 1000),
    },
};
</script>
