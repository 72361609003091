<template>
    <div id="Login" class="bg-tertiary-b h-full min-h-screen flex items-center justify-center">
        <div id="render-element"></div>
        <div class="holder w-full relative">
            <div v-if="loading" key="loading" class="text-center w-full holder flex flex-col justify-center">
                <LoadingSpinner :diameterRem="4" />
                <p class="sr-only">{{ strings.loading }}</p>
            </div>
            <div v-else class="text-center flex flex-col justify-between holder">
                <div class="w-full relative mt-16">
                    <div class="max-w-sm mx-auto px-4 pt-8">
                        <h1 class="h1 mb-8">{{ strings.login }}</h1>
                        <div class="portal-input">
                            <label class="sr-only">{{ strings.email }}</label>
                            <input
                                v-model.trim="email"
                                placeholder="user@email.com"
                                data-cy="login-email-input"
                                class="input w-full rounded-full appearance-none px-6 py-4 high-c-border"
                                type="email"
                                name="email"
                                spellcheck="false"
                                autofocus
                                @input="delayTouch(v$.email)"
                                @blur="v$.email.$touch"
                                @keyup.enter="login"
                            />
                            <div class="focus-border"></div>
                        </div>
                        <div class="portal-input mt-2">
                            <div class="relative mt-1 mb-3 rounded-full">
                                <input
                                    v-model.trim="password"
                                    class="input w-full rounded-full appearance-none px-6 py-4 high-c-border"
                                    type="password"
                                    data-cy="login-password-input"
                                    placeholder="password"
                                    autofocus
                                    @input="delayTouch(v$.password)"
                                    @blur="v$.password.$touch"
                                    @keyup.enter="login"
                                />
                                <div class="focus-border"></div>
                            </div>
                        </div>
                        <button
                            class="btn btn-larger bg-black text-white block w-full mt-6"
                            data-cy="login-button"
                            :class="{ 'opacity-90': v$.email.$invalid || v$.password.$invalid }"
                            @click="login"
                        >
                            {{ strings.login }}
                        </button>
                        <div class="text-portal-sm mb-2">
                            <div
                                class="text-sm leading-none text-lighter mt-3"
                                style="min-height: 1.75rem"
                                aria-live="polite"
                                role="region"
                                :class="{ 'opacity-0': !v$.email.$error && !v$.password.$error }"
                            >
                                <div v-for="error of v$.$errors" :key="`error-${error.$uid}`">
                                    <span v-if="error.$property === 'password'">
                                        <span v-if="error.$validator === 'required'">{{ strings["password-required"] }}</span>
                                        <span v-else>{{ strings["password-short"] }}</span>
                                    </span>
                                    <span v-if="error.$property === 'email'">
                                        <span v-if="error.$validator === 'required'">{{ strings["email-required"] }}</span>
                                        <span v-else>{{ strings["email-invalid"] }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-8 mt-2 relative z-10 text-portal-sm max-w-md mx-auto">
                    <router-link to="/password-lost" data-cy="to-register" class="inline-block mx-1 border-b relative mb-8">
                        {{ strings["forgot-password"] }}
                    </router-link>
                    <router-link to="/register" data-cy="to-register" class="block mx-1 relative">
                        {{ strings["no-acc"] }} <span class="text-bolder border-b">{{ strings.signup }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import debounce from "lodash.debounce";
import LoadingSpinner from "@/components/ui/loadingSpinner";
import { mapFields } from "vuex-map-fields";
import text from "./loginText";

// We know that passwords should be at least this length because it is required in registration
const lengthOk = (password) => password.length > 8;

export default {
    name: "Login",
    setup() {
        // https://vuelidate-next.netlify.app/#getting-started-2
        return { v$: useVuelidate() };
    },
    data() {
        return {
            password: null,
            email: null,
            loading: false,
            strings: text,
        };
    },
    components: {
        LoadingSpinner,
    },
    validations: {
        // email validation in this case also checks for '<' and '>' characters
        email: {
            required,
            email,
        },
        password: {
            required,
            lengthOk,
        },
    },
    computed: {
        ...mapFields(["highContrast", "queryParameters"]),
    },
    methods: {
        delayTouch: debounce((inputElement) => {
            if (!inputElement.$dirty) inputElement.$touch();
        }, 1000),
        async login() {
            // Because enter may trigger this method, we need to re-check the form
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.v$.email.$touch();
                console.log(this.v$.password);
                this.v$.password.$touch();
                return;
            }

            const ls_privacy_services = null; //getLSItem("privacy-services");
            this.loading = true;

            // eslint-disable-next-line global-require
            const bcrypt = require("bcryptjs");
            // TODO: Daniel: This needs to be updated... currently it doesn't consider the email when hashing on the FE.
            // Also in the register complete.
            const salt = `$2a$10$KdtO7gxB1VPlolHaUBLZUu${this.email}`,
                password_hash = bcrypt.hashSync(this.password, salt);

            this.$api.auth
                .login(this.email, password_hash, this.queryParameters, {}, ls_privacy_services)
                .then((response) => {
                    // privacy service prefs - update local storage with choices if any were saved in DB
                    // if (response.data.privacy_services && response.data.privacy_services.length > 0) {
                    //     setLSItem("privacy-services", response.data.privacy_services);
                    // }
                    // console.log(response);

                    // force page reload and redirect - ensures privacy prefs have been fetched from the DB
                    if (this.$route.query.redirect) {
                        // redirect, keeping other params intact
                        const { redirect, ...params } = this.$route.query,
                            param_keys = Object.keys(params),
                            param_vals = Object.values(params);
                        let redirect_string = redirect;

                        for (let i = 0; i < param_keys.length; i++) {
                            redirect_string += i === 0 ? `?${param_keys[i]}=${param_vals[i]}` : `&${param_keys[i]}=${param_vals[i]}`;
                        }
                        window.location.replace(redirect_string);
                    } else {
                        window.location.replace("/home");
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.holder {
    min-height: 510px;
}
</style>
