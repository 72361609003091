import { authPlugin } from "./auth.js";
import axios from "axios";
import { User, Response, Diagnosis, Condition } from "@/data/models";
import store from "../store";

export const apiPlugin = {
    // Auth API
    auth: {
        logout() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/x/logout", {
                        withCredentials: true,
                    })
                    .then((response) => {
                        authPlugin.destroyToken();
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        countdownNextRefresh(response) {
            authPlugin.setToken(response.headers.token);
            const countdown = authPlugin.millisecondsTillExpiry();
            if (countdown) {
                // refresh token 10 seocnds before expiry
                /* eslint-disable-next-line */
                setTimeout(apiPlugin.auth.token_refresh, countdown - 10000);
            }
        },
        token_refresh() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/x/token-refresh", {
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (response.status !== 202) {
                            apiPlugin.auth.countdownNextRefresh(response);
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        subscribe(email, query_parameters) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - subscribe" });
            return new Promise((resolve, reject) => {
                axios
                    .post("/x/subscribe", {
                        email,
                        query_parameters,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - subscribe - failed" });
                        reject(error);
                    });
            });
        },
        register(email, registration_source, query_parameters, survey_response, user_role = null) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - register" });
            return new Promise((resolve, reject) => {
                axios
                    .post("/x/register", {
                        email,
                        registration_source,
                        query_parameters,
                        survey_response,
                        user_role,
                    })
                    .then((response) => {
                        // Vue.prototype.$gtm.trackEvent({ event: `CE - Sign Up ${registration_source}` });
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - register - failed" });
                        reject(error);
                    });
            });
        },
        check_registration(token) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - check_registration" });
            return new Promise((resolve, reject) => {
                axios
                    .post(`/x/register/${token}`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - check_registration - failed" });
                        reject(error);
                    });
            });
        },
        complete_registration(token, password_hash) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - complete_registration" });
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/x/register/complete",
                        {
                            token,
                            password: password_hash,
                        },
                        { withCredentials: true }
                    )
                    .then((response) => {
                        apiPlugin.auth.countdownNextRefresh(response);
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - complete_registration - failed" });
                        reject(error);
                    });
            });
        },
        complete_access(token) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/x/access/${token}`, {}, { withCredentials: true })
                    .then((response) => {
                        apiPlugin.auth.countdownNextRefresh(response);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        login(email, password_hash, query_parameters, survey_response, privacy_services) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - login" });

            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/x/login",
                        {
                            email,
                            password: password_hash,
                            query_parameters,
                            survey_response,
                            privacy_services: privacy_services ? { data: privacy_services } : {},
                        },
                        { withCredentials: true }
                    )
                    .then((response) => {
                        apiPlugin.auth.countdownNextRefresh(response);
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - login - failed" });
                        reject(error);
                    });
            });
        },
        reset(email) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - reset" });
            return new Promise((resolve, reject) => {
                axios
                    .post("/x/reset", {
                        email,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - reset - failed" });
                        reject(error);
                    });
            });
        },
        check_reset(token) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - check_reset" });
            return new Promise((resolve, reject) => {
                axios
                    .post(`/x/reset/${token}`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - check_reset - failed" });
                        reject(error);
                    });
            });
        },
        complete_reset(token, password_hash) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - complete_reset" });
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/x/reset/complete",
                        {
                            token,
                            password: password_hash,
                        },
                        { withCredentials: true }
                    )
                    .then((response) => {
                        apiPlugin.auth.countdownNextRefresh(response);
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - complete_reset - failed" });
                        reject(error);
                    });
            });
        },
        unauthenticated_interest({ email, interest, query_parameters, name = "", phone = "", organisation = "", notes = "" }) {
            // Vue.prototype.$gtm.trackEvent({ event: "API - unauthenticated_interest" });
            return new Promise((resolve, reject) => {
                axios
                    .post("/x/interests", {
                        email,
                        interest,
                        query_parameters,
                        name,
                        phone,
                        organisation,
                        notes,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        // Vue.prototype.$gtm.trackEvent({ event: "API - unauthenticated_interest - failed" });
                        reject(error);
                    });
            });
        },
    },
    // User API
    // For now I have copied over only the immediately needed functions from client/src/spi/server/user.js
    user: {
        read_user() {
            return User.api().get("/user");
        },
        update_user_field(field, value) {
            return axios.put(`/user/${field}`, { value });
        },
        update_contactable_by_phone_on() {
            return axios.patch("/users/field/contactable-by-phone");
        },
        update_contactable_by_phone_off() {
            return axios.delete("/users/field/contactable-by-phone");
        },
        // Standard survey responses
        update_my_response_for_survey(survey_id, data, completing, ask) {
            return Response.api().put(`/responses/survey/${survey_id}`, { completing, ask, data });
        },
        read_my_responses_for_surveys(survey_ids) {
            return Response.api().post("/responses/surveys", { survey_ids });
        },
        // Pulse response
        // create_pulse_response_for_survey(survey_id, data, ask) {
        //     return PulseResponse.api().post(`/pulse-responses/survey/${survey_id}`, { data, ask });
        // }
        // Diagnoses
        read_my_diagnoses() {
            Diagnosis.deleteAll();
            return Diagnosis.api().get("/diagnoses");
        },
        create_diagnosis_for_condition_key(condition_key, medically_validated = true) {
            return Diagnosis.api().post(`/diagnoses/condition/key/${condition_key}`, { medically_validated });
        },
        create_diagnosis_for_condition_id(condition_id, medically_validated = true) {
            return Diagnosis.api().post(`/diagnoses/condition/${condition_id}`, { medically_validated });
        },
        delete_diagnosis_for_condition_key(id, condition_key) {
            return Diagnosis.api().delete(`/diagnoses/condition/key/${condition_key}`, { delete: id });
        },
        delete_diagnosis_for_condition_id(id, condition_id) {
            return Diagnosis.api().delete(`/diagnoses/condition/${condition_id}`, { delete: id });
        },
        // Conditions
        read_my_diagnosed_conditions() {
            return Condition.api().get("/diagnosed-conditions");
        },
        // Delete all
        delete_all_data() {
            // Delete all the vuex orm data from the store as well as submitting the request
            store.dispatch("entities/deleteAll");
            return axios.delete("/delete_all_data");
        },
        delete_account() {
            return axios.delete("/delete_account");
        },
    },
};

export const vueInstallApiPlugin = {
    install: (app) => {
        app.config.globalProperties.$api = apiPlugin;
    },
};
