import BaseModel from "./BaseModel";
import { Permission, RolePermission } from "../models";

export default class Role extends BaseModel {
    static entity = "roles";

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(""),
            description: this.attr(""),

            // m-m relationships
            permissions: this.belongsToMany(Permission, RolePermission, "role_id", "permission_id"),
        };
    }
}
