<template>
    <div id="register" class="bg-tertiary-b h-full min-h-screen relative">
        <div class="absolute left-0 top-0 h-full w-full bg-background opacity-slow" :class="{ 'opacity-0': !emailSent }"></div>
        <div id="render-element"></div>
        <div class="text-over-tertiary text-center h-full min-h-screen flex items-center justify-center px-4 pt-16 relative">
            <div class="w-full max-w-md py-12">
                <h1 class="h1">{{ strings.create }}</h1>
                <p class="max-w-xs mt-6 mx-auto">{{ strings.exp }}</p>
                <RegisterBox :focus_email="true" :registrationSource="registrationSource" @sent="emailSent = true" />
                <div class="mb-8 relative z-10 text-portal-sm max-w-md mx-auto">
                    <router-link to="/login" data-cy="to-login" class="block mx-1 relative">
                        {{ strings["have-acc"] }} <span class="text-bolder border-b">{{ strings.login }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterBox from "@/components/auth/registerBox";
import text from "./registerText";

export default {
    name: "Register",
    components: {
        RegisterBox,
    },
    data() {
        return {
            strings: text,
            emailSent: false,
        };
    },
    computed: {
        registrationSource() {
            const source = this.$store.getters.CTA_source,
                sourceString = source ? `register<-${source}` : "register";

            return sourceString;
        },
    },
};
</script>

<style scoped>
.opacity-slow {
    transition: opacity 0.9s ease-in-out;
}
</style>
