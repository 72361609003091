import schema from "./schema";
import BlogpostImage from "./blogpostImage";
import HtmlContent from "./htmlContent";
import ImageCredit from "./imageCredit";
// h - a conventional alias for createElement
import { h, resolveComponent, markRaw } from "vue";

export default {
    name: "RichTextRenderer",
    components: {
        BlogpostImage,
        HtmlContent,
        ImageCredit,
    },
    props: {
        document: {
            type: Object,
            required: true,
        },
        context: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            componentsForCustomNodes: {
                image: markRaw(resolveComponent("BlogpostImage")),
                html_content: markRaw(resolveComponent("HtmlContent")),
                image_credit_list: markRaw(resolveComponent("ImageCredit")),
            },
        };
    },
    render() {
        const toRender = this.document.content.map((node, nodeIndex) => this.processNode(node, nodeIndex));
        // See rich-text-content.css for generally applied styles
        return h("div", { class: "rich-text-content" }, toRender);
    },
    methods: {
        processNode(node, nodeIdentifier) {
            // Recursive function that renders ProseMirror's nodes as VNodes.
            if (node.marks && node.marks.length) {
                // For each node, if it is one with 'marks' (e.g. <i>) return the rendered marks and text
                return this.renderMarks(node.marks, node.text);
            } else if (node.text) {
                // If there are no 'marks', but there is text directly within the node, render just the text
                return node.text;
            } else {
                // Otherwise, we need to render a particular node type
                let { tag, data = {}, skipContent } = this.getMatchingNode(node, `${nodeIdentifier}`);

                // If that node also has content inside, we need to call this function again for the child nodes
                if (node.content && !skipContent) {
                    return h(
                        tag,
                        data,
                        node.content.map((childNode, childNodeIndex) => this.processNode(childNode, `${nodeIdentifier}-${childNodeIndex}`))
                    );
                } else {
                    return h(tag, data, node.content);
                }
            }
        },
        renderMarks(marks, text) {
            // Returns nested marks and the inner text. Ex: <strike><b><i>Hello World</i></b></strike>
            // Get the first 'mark'. We'll always get a 'tag' back, and possbily 'data' or 'skipContent'
            const { tag, data, skipContent } = this.getMatchingMark(marks[0]);

            if (marks.length === 1 || skipContent) {
                // If this is the only mark, we can render
                return h(tag, data, text);
            } else {
                // Otherwise we want to call this function again
                return h(tag, data, [this.renderMarks(marks.slice(1), text)]);
            }
        },
        getMatchingMark(item) {
            if (typeof schema.marks[item.type] !== "function") {
                console.info(`No schema found for {${item.type}} mark!`);
                // Return empty element
                return { tag: "span" };
            }
            return schema.marks[item.type](item);
        },
        getMatchingNode(item, nodeIdentifier) {
            if (Object.keys(this.componentsForCustomNodes).includes(item.type)) {
                // Custom nodes are registered here, rather than in schema, as we need to use resolveComponent
                // https://v3.vuejs.org/guide/migration/render-function-api.html#_2-x-syntax-4
                // Pass through any props that are needed in 'data'
                const data = { ...item.attrs };
                if (item.type === "html_content") {
                    data.nodeContent = item.content;
                    // Use this to try to ensure we have unique keys
                    data.nodeIdentifier = nodeIdentifier;
                } else if (item.type === "image_credit_list") {
                    data.node = item;
                }

                return { tag: this.componentsForCustomNodes[item.type], data };
            } else if (typeof schema.nodes[item.type] !== "function") {
                console.info(`No schema found for {${item.type}} node!`);
                // Return empty element
                return { tag: "div", skipContent: true };
            } else {
                // Return node info from schema
                const nodeInfo = schema.nodes[item.type](item);
                if (item.type === "heading") nodeInfo.data.onClick = this.handleClick;
                return nodeInfo;
            }
        },
        handleClick(e) {
            if (e.target && e.target.id) this.$emit("heading-clicked", e.target.id);
        },
    },

    // function inheritCssClasses(data) {
    //     let classes = [];
    //     if (Array.isArray(data.class)) {
    //         classes = data.class;
    //     } else if (typeof data.class === "object") {
    //         const keys = Object.keys(data.class);
    //         classes = keys.filter((key) => data.class[key]);
    //     } else {
    //         classes = [data.class];
    //     }
    //     return classes;
    // }

    // const
};
