<template>
    <div class="toggle-holder">
        <input
            :id="id"
            class="sr-only"
            :checked="value"
            type="checkbox"
            :aria-label="`${description} ${value ? strings.on : strings.off} ${strings.spacebar}`"
            @change="$emit('input', $event.target.checked)"
        />
        <label aria-hidden="true" :for="id" class="toggle-checkbox w-16 text-bolder" :class="`${value ? 'toggle-on' : 'toggle-off'}`">
            <span ref="on-label" class="on-label">{{ strings.on }}</span>
            <span ref="off-label" class="off-label">{{ strings.off }}</span>
            <span class="marker" :style="`transform: translateX(${64 - 25}px)`" />
        </label>
    </div>
</template>

<script>
import text from "./onOffToggleText";
export default {
    name: "OnOffToggle",
    emits: ["input"],
    props: {
        value: Boolean,
        id: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            strings: text,
        }
    }
};
</script>

<style scoped>
.toggle-checkbox {
    @apply relative text-xs bg-light-grey text-black h-6 flex rounded-full justify-between items-center px-2 cursor-pointer uppercase;
}

.toggle-checkbox:before {
    content: "";
    @apply absolute left-0 right-0 bottom-0 top-0 bg-black;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.toggle-checkbox span {
    @apply absolute block;
    z-index: 1;
    right: 12px;
    transition: opacity 0.3s ease-in-out;
}

.toggle-checkbox .on-label {
    @apply text-white;
    left: 12px;
    right: auto;
    opacity: 0;
}

.toggle-checkbox .marker {
    @apply bg-white w-4 h-4 rounded-full;
    transition: transform 0.3s ease-in-out;
    left: 5px;
    right: auto;
}

.toggle-checkbox.toggle-on:before {
    opacity: 1;
}

.toggle-checkbox.toggle-on span.off-label {
    opacity: 0;
}

.toggle-checkbox.toggle-on span.on-label {
    opacity: 1;
}

.toggle-checkbox.toggle-off span.marker {
    transform: translateX(0px) !important;
}
</style>
