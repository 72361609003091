/* This component is designed to make lazyloading a background image easy, with the option to have a mobile version. It will fill a containing
element(which will be assumed to be full width (at least on non-xl screens) Any urls should still be correctly sized and optimised. */
<template>
    <div class="absolute top-0 bottom-0 left-0 right-0" ref="target">
        <div
            class="sano-bg-image opacity-transition"
            :class="`${backgroundSizePosClasses} ${isOnScreen ? '' : 'opacity-0'} ${!!srcW768 ? 'sano-bg-image-desktop' : ''}`"
            :style="`background-image: url(${isOnScreen ? mainSrc : ''});`"
        ></div>
        <div
            class="sano-bg-image sano-bg-image-mob opacity-transition"
            :class="{ 'opacity-0': !isOnScreen }"
            :style="`background-image: url(${isOnScreen ? srcW768 : ''});`"
        ></div>
    </div>
</template>

<script>
export default {
    name: "LazyLoadingBGimage",
    props: {
        mainSrc: {
            type: String,
            required: true,
        },
        srcW768: String,
        backgroundSizePosClasses: {
            type: String,
            default: "bg-cover bg-center",
        },
    },
    data() {
        return {
            isOnScreen: false,
            observer: null,
        };
    },
    mounted() {
        if (this.$refs["target"]) {
            this.target = this.$refs["target"];
            // No options are passed, only callback, as the defaults are exactly what we need
            this.observer = new IntersectionObserver(this.onElementObserved);
            this.observer.observe(this.target);
        }
    },
    beforeUnmount() {
        this.observer.disconnect();
    },
    methods: {
        onElementObserved(entries) {
            if (entries[0].isIntersecting) {
                // Load the image, stop observing
                this.isOnScreen = true;
                this.observer.unobserve(this.target);
            }
        },
    },
};
</script>

<style scoped>
.opacity-transition {
    transition: opacity 0.3s ease-in-out;
}

.sano-bg-image {
    @apply absolute top-0 left-0 right-0 bottom-0;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .sano-bg-image-mob {
        display: none;
        background-image: none !important;
    }
}

/* This class is only applied if there is a mobile version included as a prop */
@media (max-width: 767px) {
    .sano-bg-image-desktop {
        display: none;
        background-image: none !important;
    }
}
</style>
