import BaseModel from "./BaseModel";

export default class PublicData extends BaseModel {
    static entity = "publicdata";

    static fields() {
        return {
            id: this.attr(""),
            participant_id: this.attr(""),
            date_published: this.attr(""),
            provider: this.attr(""),
            data_source: this.attr(""),
            metadata: this.attr(""),
            participant_url: this.attr(""),
            download_url: this.attr(""),
            description: this.attr(""),
            file_url: this.attr(""),
            file_size: this.attr(""),
            file_hash: this.attr(""),
            processed: this.attr(""),
            imputation_url: this.attr(""),
            preimputation_url: this.attr(""),
        };
    }
}
