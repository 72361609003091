import Wrapper from "./components/wrapper";
import NotFound from "./pages/notFound";
import Landing from "./pages/landing";

import Register from "./pages/auth/register";
import PasswordLost from "./pages/auth/passwordLost";
import Login from "./pages/auth/login";

import BlogPage from "./pages/content/blog";
import Privacy from "./pages/privacyTerms/privacy";
import Terms from "./pages/privacyTerms/terms";
// import Reports from "./pages/reports";

const RegisterComplete = () => import(/* webpackChunkName: "password_setting" */ "./pages/auth/registerComplete"),
    PasswordReset = () => import(/* webpackChunkName: "password_setting" */ "./pages/auth/passwordReset"),
    Account = () => import(/* webpackChunkName: "logged_in" */ "./pages/loggedIn/account"),
    Home = () => import(/* webpackChunkName: "logged_in" */ "./pages/loggedIn/home"),
    Welcome = () => import(/* webpackChunkName: "surveys" */ "./pages/loggedIn/welcome"),
    Survey = () => import(/* webpackChunkName: "surveys" */ "./pages/loggedIn/survey"),
    FindHelp = () => import(/* webpackChunkName: "findhelp" */ "./pages/findHelp");

export default {
    getRoutes({ config }) {
        // First include routes which will exist for every portal
        const base_routes = [
            // ----------------------------------------- Landing & Content routes
            { path: "", component: Landing, name: "landing", meta: { title: "Welcome" } },
            { path: "blog/:key", name: "blogpost", component: BlogPage },
            // ----------------------------------------- Privacy, Ts & Cs
            { path: "privacy", name: "privacy", component: Privacy },
            { path: "terms", name: "terms", component: Terms },
            // ----------------------------------------- Logged in only routes
            { path: "home", name: "home", component: Home, meta: { requiresAuth: true, title: "Home" } },
            { path: "welcome", name: "welcome", component: Welcome, meta: { requiresAuth: true, title: "Welcome" } },
            { path: "account", name: "account", component: Account, meta: { requiresAuth: true, title: "Account" } },
            // -------------------- Surveys
            { path: "survey/:id", name: "survey", component: Survey, meta: { requiresAuth: true, title: "Survey" } },
            // ----------------------------------------- Auth routes
            { path: "register", name: "register", component: Register, meta: { requiresGuest: true, title: "Register" } },
            {
                path: "complete/:token",
                name: "register-complete",
                component: RegisterComplete,
                meta: { requiresGuest: true, title: "Register complete" },
            },
            { path: "password-lost", name: "password-lost", component: PasswordLost, meta: { requiresGuest: true, title: "Lost Password" } },
            { path: "resetpass/:token", name: "password-reset", component: PasswordReset, meta: { title: "Reset Password" } },
            { path: "login", name: "login", component: Login, meta: { requiresGuest: true, title: "Log in" } }, 
            // ----------------------------------------- 404
            { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
        ];

        // Add extra routes depending on config settings
        // The findHelp is not a general web help page but a tool for finding help near you in the US (previously called Aunt Bertha)
        if (config.findhelp_auntbertha_page) {
            base_routes.push({ path: "/findhelp", name: "find-help", component: FindHelp });
        }

        // Return the full set of routes for this portal (all using the wrapper currently)
        const routes = [
            {
                path: "/",
                name: "",
                component: Wrapper,
                children: base_routes,
            },
        ];
        return routes;
    },
};
