import BaseModel from "./BaseModel";
import { Condition } from "../models";

export default class Diagnosis extends BaseModel {
    static entity = "diagnoses";

    static fields() {
        return {
            id: this.attr(null),
            condition_id: this.attr(null),
            condition: this.belongsTo(Condition, "condition_id"),
            user: this.attr(""),
            self_reported: this.attr(""),
            medically_validated: this.attr(""),
            clinically_validated: this.attr(""),
        };
    }
}
