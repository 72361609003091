/* Simple, single pop-up box for 'more info' type things. Uses the first parent with a relative position for layout */
<template>
    <div>
        <button
            aria-haspopup="true"
            :aria-expanded="expanded"
            :aria-label="buttonAria"
            data-cy="popup-button"
            :class="buttonClasses"
            @click="changeExpanded()"
        >
            <slot name="button-content"></slot>
        </button>
        <transition name="fade-down">
            <div v-show="expanded" class="extra-info" :class="popupClasses" :style="`transform: ${transform};`">
                <slot name="popup-content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Popup",
    props: {
        buttonAria: {
            type: String,
            default: "toggle show/hide popup info",
        },
        buttonClasses: {
            type: String,
            default: "w-8 h-8 block relative",
        },
        popupClasses: {
            type: String,
            default: "bg-background p-3 rounded-lg w-56 border border-mid-grey text-base",
        },
        transform: {
            type: String,
            default: "translateY(100%)",
        },
        // Allow for a close instruction to come from the parent
        closePopupCount: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            expanded: false,
        };
    },
    watch: {
        closePopupCount() {
            if (this.expanded) this.changeExpanded();
        },
    },
    methods: {
        changeExpanded() {
            this.expanded = !this.expanded;
        },
    },
};
</script>

<style scoped>
.fade-down-enter-active,
.fade-down-leave-active {
    transition: opacity 0.3s ease-in-out;
}

.fade-down-leave-to,
.fade-down-enter {
    opacity: 0;
}

.extra-info {
    @apply absolute left-0;
    bottom: -0.5rem;
    z-index: 40;
}
</style>
