import { inject } from "vue";
import Swal from "sweetalert2";

const SwalSymbol = Symbol();

export function useSwal() {
    return inject(SwalSymbol);
}

export const toastSwal = Swal.mixin({
    // Use when we need to show a short warning or error message
    position: "top",
    customClass: {
        popup: "portal-toast-notification",
    },
    iconHtml: `<div class="stroke-current w-10 h-10"><svg viewBox="0 0 24 24">
        <path d="M12.0015 9V11M12.0015 15H12.0115M5.0733 19H18.9297C20.4693 19 21.4316 17.3333 20.6618 16L13.7336 4C12.9637 2.66667 11.0392 2.66667 10.2694 4L3.34125 16C2.57145 17.3333 3.5337 19 5.0733 19Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg></div>`,
});

export const choiceSwal = Swal.mixin({
    // Use when a choice is needed from the user for next steps or more text is involved
    customClass: {
        popup: "portal-swal",
    },
    iconHtml: `<div class="stroke-current w-10 h-10"><svg viewBox="0 0 24 24">
        <path d="M12.0015 9V11M12.0015 15H12.0115M5.0733 19H18.9297C20.4693 19 21.4316 17.3333 20.6618 16L13.7336 4C12.9637 2.66667 11.0392 2.66667 10.2694 4L3.34125 16C2.57145 17.3333 3.5337 19 5.0733 19Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg></div>`,
});

export const vueUseSwal = {
    install: (app) => {
        app.config.globalProperties.$toastSwal = toastSwal;
        app.config.globalProperties.$choiceSwal = choiceSwal;
        app.provide(SwalSymbol, Swal);
    },
};
