<template>
    <div class="accordion w-full relative">
        <!-- accordion button -->
        <button
            data-cy="accordion-btn"
            class="block w-full"
            :class="expanded ? buttonOpenClasses : buttonClosedClasses"
            aria-haspopup="true"
            :aria-expanded="expanded ? 'true' : 'false'"
            :tabindex="disableLinks ? '-1' : '0'"
            :disabled="disableLinks ? true : false"
            @click="toggleExpanded"
        >
            <div class="relative z-10 flex items-center w-full">
                <div class="relative flex-grow">
                    <slot name="insideButton"></slot>
                </div>
                <div v-if="showPlusMinus" class="flex-shrink-0 w-6">
                    <PlusMinusToggle :isOpen="expanded" />
                </div>
            </div>
        </button>
        <!-- accordion content -->
        <div :class="[{ 'overflow-hidden': isExpanding }, { 'opacity-0 absolute': gettingHeights }]">
            <transition name="expanded">
                <div
                    v-show="expanded || show_all"
                    :class="[{ 'no-max-height': show_all }, { 'no-transition': gettingHeights }]"
                    :aria-hidden="expanded ? 'false' : 'true'"
                    :style="`max-height: ${height}px`"
                    :ref="`${name}-content`"
                >
                    <slot name="content"></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import PlusMinusToggle from "@/components/ui/plusMinusToggle";

export default {
    name: "Accordion",
    components: {
        PlusMinusToggle,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        buttonClosedClasses: {
            type: String,
            default: "text-left py-6 border-t border-mid-grey",
        },
        buttonOpenClasses: {
            type: String,
            default: "text-left py-6 border-t border-primary",
        },
        disableLinks: Boolean,
        showPlusMinus: Boolean,
    },
    data() {
        return {
            height: 500,
            show_all: false,
            gettingHeights: false,
            expanded: false,
            isExpanding: false,
        };
    },
    computed: {
        resizeCount() {
            return this.$store.state.resizeCount;
        },
    },
    watch: {
        resizeCount() {
            this.getHeights();
        },
    },
    mounted() {
        this.getHeights();

        // While developing, we have a flash of unstyled content and need a backup check after this is resolved
        // TODO: (but lower priority as only a dev irritation - prevent FOUC)
        setTimeout(() => {
            if (this.getHeights !== undefined) this.getHeights();
        }, 500);
    },
    methods: {
        getHeights() {
            this.gettingHeights = true;

            setTimeout(() => {
                this.show_all = true;
                setTimeout(() => {
                    const thisContent = this.$refs[`${this.name}-content`];
                    if (thisContent) this.height = thisContent.clientHeight;
                    this.show_all = false;

                    setTimeout(() => {
                        if (this.gettingHeights) this.gettingHeights = false;
                    }, 100);
                }, 0);
            }, 0);
        },
        toggleExpanded() {
            this.isExpanding = true;
            this.$nextTick(() => {
                this.expanded = !this.expanded;

                // Allow overflow when accordion is not expanding
                setTimeout(() => {
                    if (this.isExpanding) this.isExpanding = false;
                }, 600);
            });
        },
    },
};
</script>

<style scoped>
/* expand transition */
.expanded-enter-active:not(.no-transition),
.expanded-leave-active:not(.no-transition) {
    transition: all 0.6s ease-in-out;
}

.expanded-enter-from:not(.no-transition),
.expanded-leave-to:not(.no-transition) {
    opacity: 0;
    max-height: 0 !important;
}

.no-transition {
    transition-duration: 0s !important;
}
.no-max-height {
    max-height: none !important;
}
</style>
