<template>
    <div id="RegisterBox" class="w-full relative holder">
        <transition name="portal-fade" mode="out-in">
            <div v-if="loading" key="loading" class="text-center w-full h-full flex items-center justify-center holder">
                <LoadingSpinner :diameterRem="4" />
                <p class="sr-only">{{ strings.loading }}</p>
            </div>
            <div v-else-if="completed" key="complete" class="text-center w-full h-full flex flex-col justify-center holder">
                <div class="bg-black w-1/3 h-px mx-auto my-3 sm:mb-8"></div>

                <div aria-live="polite" class="pt-1">
                    <div v-show="showAfterCompletedForAriaLive" data-cy="register-email-success">
                        <p class="leading-tight">{{ strings.invite }}</p>
                        <p class="text-base text-bolder pt-1">{{ strings["check-inbox"] }}</p>
                    </div>
                </div>

                <emailClientLinks />
            </div>
            <div v-else key="initial" class="holder w-full h-full relative">
                <div class="pt-8 max-w-sm mx-auto px-4 md:px-10">
                    <emailInput cypressLabel="register-email" :buttonText="strings.signup" @valid-email-input="register" />
                    <!-- Ts & Cs -->
                    <p class="text-portal-xs mt-2 mb-6">
                        {{ strings.agreement }}
                        <router-link to="/terms" class="relative border-b inline-block"> {{ strings.tos }} </router-link>
                        {{ strings.and }}
                        <router-link to="/privacy" class="relative border-b inline-block"> {{ strings.privacy }}</router-link>
                        {{ strings.loop }}
                    </p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/ui/loadingSpinner";
import EmailClientLinks from "@/components/auth/emailClientLinks";
import EmailInput from "@/components/auth/emailInput";
import { mapFields } from "vuex-map-fields";
import text from "./registerBoxText";

export default {
    name: "RegisterBox",
    components: {
        LoadingSpinner,
        EmailClientLinks,
        EmailInput,
    },
    props: {
        registrationSource: {
            type: String,
            default: "",
        },
        userRole: String,
    },
    data() {
        return {
            loading: false,
            completed: false,
            showAfterCompletedForAriaLive: false,
            strings: text,
        };
    },
    computed: {
        ...mapFields(["queryParameters"]),
    },
    methods: {
        register(validEmailValue) {
            this.loading = true;
            // Check for someone being in the middle of registering a kit
            // const kitCode = this.$route.query.kit_registration,
            //     reg_source_plus_kit = kitCode ? `${this.registrationSource}?kit_code=${kitCode}` : this.registrationSource;

            const params = [validEmailValue, this.registrationSource, this.queryParameters];
            // if (this.userRole) params.push(this.userRole);

            this.$api.auth
                .register(...params)
                .then(() => {
                    setTimeout(() => {
                        this.loading = false;
                        this.completed = true;
                        this.$emit("sent");
                        this.handleFocusAria();
                    }, 300);
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        handleFocusAria() {
            // Show the completion text after the complete section is shown so that the aria-live
            // section picks up on this change. Then focus this section.
            setTimeout(() => {
                this.showAfterCompletedForAriaLive = true;
                if (this.$refs.first_email_link) this.$refs.first_email_link.focus();
            }, 0);
        },
    },
};
</script>

<style scoped>
.holder {
    min-height: 296px;

    @media (min-width: 576px) {
        min-height: 262px;
    }
}
</style>
