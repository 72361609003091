<template>
    <router-link
        v-if="portalConfig"
        :to="$auth.loggedIn() ? '/home' : '/'"
        class="block relative"
        :style="`height: ${logoHeight}px; width: ${logoHeight / portalConfig.org_logo_hw_ratio}px;`"
    >
        <span class="sr-only">{{ strings.home.replace("{organisation}", portalConfig.org_name) }}</span>
        <div class="text-white logo-colour-version" :style="`padding: ${portalConfig.org_logo_padding}px`" :class="{ 'opacity-0': !showWhite }">
            <svg class="use-svg mr-auto" aria-hidden="true">
                <use xlink:href="#logo" />
            </svg>
        </div>
        <div class="text-black logo-colour-version" :style="`padding: ${portalConfig.org_logo_padding}px`" :class="{ 'opacity-0': showWhite }">
            <svg class="use-svg mr-auto" aria-hidden="true">
                <use xlink:href="#logo" />
            </svg>
        </div>
    </router-link>
</template>

<script>
import text from "./navLogoText";

export default {
    name: "NavLogo",
    props: {
        showWhite: Boolean,
    },
    data() {
        return {
            logoHeight: 52,
            strings: text,
        };
    },
};
</script>

<style scoped>
.logo-colour-version {
    @apply absolute top-0 left-0 w-full h-full transition-opacity duration-300 ease-in-out;
}
</style>
