import { Database } from "@vuex-orm/core";

import PublicData from "./models/PublicData";
import Blogpost from "./models/Blogpost";
import User from "./models/User";
import UserRole from "./models/UserRole";
import Role from "./models/Role";
import RolePermission from "./models/RolePermission";
import Permission from "./models/Permission";
import Survey from "./models/Survey";
import Response from "./models/Response";
import Diagnosis from "./models/Diagnosis";
import Condition from "./models/Condition";

// import Event from "./models/Event";
// import StudySurvey from "./models/StudySurvey";
// import Enrollment from "./models/Enrollment";
// import Referral from "./models/Referral";
// import Genome from "./models/Genome";
// import Interest from "./models/Interest";
// import Report from "./models/Report";
// import ReadReport from "./models/ReadReport";
// import Order from "./models/Order";
// import Kit from "./models/Kit";
// import Partner from "./models/Partner";
// import PartnerStudy from "./models/PartnerStudy";
// import BlogpostCondition from "./models/BlogpostCondition";
// import Eligibility from "./models/Eligibility";
// import ConditionStudy from "./models/ConditionStudy";
// import Site from "./models/Site";
// import Snp from "./models/Snp";
// import StudySite from "./models/StudySite";
// import Patient from "./models/Patient";
// import Visit from "./models/Visit";
// import Podcast from "./models/Podcast";
// import PodcastCondition from "./models/PodcastCondition";
// import PulseResponse from "./models/PulseResponse";
// import Study from "./models/Study";
// import Tag from "./models/Tag";
// import Consignment from "./models/Consignment";
// import OrderConsignment from "./models/OrderConsignment";
// import SiteUser from "./models/SiteUser";
// import OrderNote from "./models/OrderNote";
// import KitNote from "./models/KitNote";
// import PartnerCondition from "./models/PartnerCondition";

const database = new Database();
database.register(PublicData);
database.register(Blogpost);
database.register(User);
database.register(Role);
database.register(RolePermission);
database.register(Permission);
database.register(UserRole);
database.register(Survey);
database.register(Response);
database.register(Condition);
database.register(Diagnosis);
// database.register(Event);
// database.register(Study);
// database.register(StudySurvey);
// database.register(Enrollment);
// database.register(Genome);
// database.register(Interest);
// database.register(BlogpostCondition);
// database.register(Report);
// database.register(Referral);
// database.register(ReadReport);
// database.register(Order);
// database.register(Kit);
// database.register(Partner);
// database.register(PartnerStudy);
// database.register(Eligibility);
// database.register(ConditionStudy);
// database.register(Site);
// database.register(Snp);
// database.register(StudySite);
// database.register(Patient);
// database.register(Visit);
// database.register(Podcast);
// database.register(PodcastCondition);
// database.register(Tag);
// database.register(Consignment);
// database.register(OrderConsignment);
// database.register(SiteUser);
// database.register(PulseResponse);
// database.register(OrderNote);
// database.register(KitNote);
// database.register(PulseResponse);
// database.register(PartnerCondition);

export {
    database,
    PublicData,
    Blogpost,
    User,
    Role,
    Permission,
    RolePermission,
    UserRole,
    Survey,
    Response,
    Condition,
    Diagnosis,
    // Event,
    // Study,
    // StudySurvey,
    // Enrollment,
    // Genome,
    // Interest,
    // BlogpostCondition,
    // Report,
    // Referral,
    // ReadReport,
    // Order,
    // Kit,
    // Partner,
    // PartnerStudy,
    // Eligibility,
    // ConditionStudy,
    // Site,
    // Snp,
    // StudySite,
    // Patient,
    // PulseResponse,
    // Visit,
    // Podcast,
    // PodcastCondition,
    // Tag,
    // Consignment,
    // OrderConsignment,
    // SiteUser,
    // OrderNote,
    // KitNote,
    // PartnerCondition,
};
