/* This component is designed to make using srcset easy, and lazily load the image when it is needed. Any urls should still be correctly sized and
optimised */

<template>
    <div
        :style="`padding-top: ${imageHWRatio * 100}%;`"
        :class="{ 'opacity-0': !isOnScreen & !dontFadeIn }"
        class="relative w-full opacity-transition"
        ref="target"
    >
        <img class="absolute centered-img" :src="isOnScreen || dontFadeIn ? mainSrc : ''" :alt="altDescription" />
        <!-- <img
            class="absolute centered-img"
            :src="isOnScreen || dontFadeIn ? require(`@/assets/temporary_images/${mainSrc}`) : ''"
            :alt="altDescription"
        /> -->
        <!-- Todo: re-implement src set once we are using a media repo -->
        <!-- :srcset="isOnScreen || dontFadeIn ? srcSet : ''" -->
    </div>
</template>

<script>
export default {
    name: "LazyLoadingImage",
    props: {
        imageHWRatio: {
            type: Number,
            required: true,
        },
        mainSrc: {
            type: String,
            required: true,
        },
        srcW1440: String,
        srcW768: String,
        srcW576: String,
        dontFadeIn: Boolean,
        altDescription: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isOnScreen: false,
            observer: null,
        };
    },
    mounted() {
        if (this.$refs["target"]) {
            this.target = this.$refs["target"];
            // No options are passed, only callback, as the defaults are exactly what we need
            this.observer = new IntersectionObserver(this.onElementObserved);
            this.observer.observe(this.target);
        }
    },
    beforeUnmount() {
        this.observer.disconnect();
    },
    methods: {
        onElementObserved(entries) {
            if (entries[0].isIntersecting) {
                // Load the image, stop observing
                this.isOnScreen = true;
                this.observer.unobserve(this.target);
            }
        },
    },
    computed: {
        // srcSet() {
        //     let srcSet = this.main_src;
        //
        //     if (this.srcW1440) srcSet += `, ${this.srcW1440} 1440w`;
        //     if (this.src_w_768) srcSet += `, ${this.srcW768} 768w`;
        //     if (this.src_w_576) srcSet += `, ${this.srcW576} 576w`;
        //
        //     return srcSet;
        // },
    },
};
</script>

<style scoped>
.opacity-transition {
    transition: all 0.3s ease-in-out;
}

.centered-img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
