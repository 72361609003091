/* The modal component was used for the 'join email list' popup before users were able to sign in. Unlike the popup component, it takes over the
screen as there is an overlay, and vue 'teleport' is used to move the modal content out of the document flow. It can be used more similarly to
sweetalert, but for more than just warnings / confirmations as we have much more control of what goes inside */
<template>
    <div>
        <button
            @click="toggleModal"
            :data-cy="`${name}-modal-btn`"
            :ref="`${name}-modal-open`"
            :aria-haspopup="true"
            :aria-expanded="modalOpen"
            class="relative rounded-full"
        >
            <slot name="insideButton"></slot>
        </button>

        <teleport to="#app">
            <transition name="modal-fade" appear>
                <div
                    v-show="modalOpen"
                    :data-cy="`${name}-modal`"
                    class="h-screen w-full fixed left-0 top-0 overflow-hidden"
                    :class="{ 'hide-focus-children': !lastInteractionKey }"
                    style="z-index: 102"
                >
                    <div class="absolute top-0 left-0 w-full h-full" style="background: rgba(0, 0, 0, 0.7)" @mousedown="toggleModal"></div>
                    <div class="absolute w-full h-px" :class="`vertical-${positionVertical}`">
                        <div
                            class="w-full relative inner"
                            :class="[{ 'mx-auto': positionHorizontal === 'center' }, { 'ml-auto': positionHorizontal === 'right' }]"
                            :style="`max-width: ${maxWidthRem}rem;`"
                        >
                            <div :class="modalClasses">
                                <div class="w-full flex items-center">
                                    <h2 v-if="shortTitle" class="block pl-1 pr-2 overline mb-0">{{ shortTitle }}</h2>
                                    <button
                                        :ref="`${name}-modal-close`"
                                        :aria-label="strings.close"
                                        @click="toggleModal"
                                        class="relative block ml-auto w-8 h-8 p-2"
                                    >
                                        <svg class="use-svg">
                                            <use xlink:href="#close" />
                                        </svg>
                                    </button>
                                </div>
                                <slot name="insideModal"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </teleport>
    </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import text from "./modalText";

export default {
    name: "Modal",
    props: {
        name: {
            type: String,
            required: true,
        },
        modalClasses: {
            type: String,
            default: "p-4 bg-tertiary-b rounded-lg mx-4",
        },
        positionVertical: {
            type: String,
            default: "center",
        },
        positionHorizontal: {
            type: String,
            default: "center",
        },
        maxWidthRem: {
            type: Number,
            default: 36,
        },
        shortTitle: String,
    },
    data() {
        return {
            modalOpen: false,
            scrollPosition: 0,
            classList: ["overflow-y-scroll", "fixed", "left-0", "right-0"],
            strings: text,
        };
    },
    computed: {
        ...mapFields(["lastInteractionKey"]),
    },
    watch: {
        modalOpen(open) {
            const elementToPreventScrolling = document.getElementById("wrapper");

            if (open) {
                // VueJS has updated the DOM based on the data change
                this.$nextTick(() => {
                    const bounds = elementToPreventScrolling.getBoundingClientRect();
                    this.scrollPosition = bounds.top;
                    elementToPreventScrolling.style.top = `${bounds.top}px`;
                    elementToPreventScrolling.classList.add(...this.classList); // disable body scroll
                });
            } else {
                // overlay closed > re-enable body scroll
                elementToPreventScrolling.style.top = "auto";
                elementToPreventScrolling.classList.remove(...this.classList); // enable body scroll
                this.$nextTick(() => {
                    window.scrollTo(0, this.scrollPosition * -1);
                });
            }
        },
    },
    methods: {
        toggleModal() {
            this.modalOpen = !this.modalOpen;

            setTimeout(() => {
                if (this.modalOpen !== undefined && this.lastInteractionKey) this.manageFocus();
                this.$emit("modal-closed");
            }, 0);
        },
        manageFocus() {
            const toFocus = this.modalOpen ? `${this.name}-modal-close` : `${this.name}-modal-open`;
            if (this.$refs[toFocus]) this.$refs[toFocus].focus();
        },
    },
};
</script>

<style scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.45s ease-in-out;
}

.modal-fade-leave-to,
.modal-fade-enter-from {
    opacity: 0;
}

.vertical-top {
    top: 0%;
}

.vertical-bottom {
    bottom: 0%;
}

.vertical-center {
    top: 50%;
}

.vertical-center .inner {
    transform: translateY(-50%);
}
</style>
