<template>
    <modal :name="`joinEmailList-${id}`" @modal-closed="resetModal">
        <template v-slot:insideButton>
            <div :class="`relative text-portal-sm text-white block rounded-full ${buttonClasses}`">
                <div class="border border-white keep-updated absolute" :class="{ 'opacity-0': !showWhite }">
                    <span class="hidden lg:block">{{ strings.update }}</span>
                    <span class="block lg:hidden">{{ strings["update-short"] }}</span>
                </div>
                <div class="bg-black keep-updated absolute" aria-hidden="true" :class="{ 'opacity-0': showWhite }">
                    <span class="hidden lg:block">{{ strings.update }}</span>
                    <span class="block lg:hidden">{{ strings["update-short"] }}</span>
                </div>
            </div>
        </template>
        <template v-slot:insideModal>
            <div class="w-full relative holder">
                <transition name="portal-fade-fix-vertical" mode="out-in">
                    <div
                        v-if="joinedEmailList"
                        class="w-full text-center pb-2 pt-0 sm:px-4 sm:pb-6 lg:pt-2 lg:px-8 flex flex-col justify-center holder"
                    >
                        <h2 class="h2 mb-3 sm:mb-8 display-text">{{ strings.thanks }}</h2>
                        <p class="mb-5">{{ strings.email.replace("{email}", joinedEmailList) }}</p>
                    </div>
                    <div v-else class="w-full text-center sm:px-4 lg:pt-2 lg:px-8 flex flex-col justify-center holder">
                        <h2 class="h2 mb-3 lg:mb-8 lg:px-6 display-text">{{ strings.more }}</h2>
                        <p class="mb-5">{{ strings.growth.replace("{organisation}", portalConfig.org_name) }}</p>
                        <div class="w-full max-w-sm mx-auto md:px-6">
                            <EmailInput @valid-email-input="addToEmailList" buttonText="Keep me updated" noPadding />
                        </div>
                        <p class="mb-4 text-portal-sm sm:px-10">
                            {{ strings.data }}
                        </p>
                    </div>
                </transition>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from "@/components/ui/modal";
import EmailInput from "@/components/auth/emailInput";
import { mapFields } from "vuex-map-fields";
import text from "./joinEmailListText";

export default {
    name: "SignUpLoginButtons",
    components: {
        Modal,
        EmailInput,
    },
    data() {
        return {
            strings: text,
        };
    },
    computed: {
        ...mapFields(["joinedEmailList"]),
    },
    props: {
        showWhite: Boolean,
        // Default classes are for when this is used in the nav top bar.
        // Padding is used to get more varied w/h options with tailwind classes.
        buttonClasses: {
            type: String,
            default: "pl-16 pr-16 lg:pl-24 lg:pr-24 pt-4 pb-5 lg:pt-6",
        },
        id: {
            type: String,
            default: "",
        },
    },
    methods: {
        addToEmailList(validEmailValue) {
            this.$api.auth.subscribe(validEmailValue, this.$route.query.code);
            // Keep what is shown consistent across instances of the popup
            this.joinedEmailList = validEmailValue;
        }, 
        resetModal() {
            this.joinedEmailList = null;
        },
    },
};
</script>

<style scoped>
.keep-updated {
    @apply px-6 py-3 top-0 left-0 h-full w-full rounded-full transition-opacity duration-300 ease-in-out flex flex-col justify-center;
    line-height: 0;
}

@media (max-width: 991px) {
    .keep-updated {
        @apply px-3 pt-3;
        padding-bottom: 0.8rem;
    }
}

.holder {
    min-height: 432px;
}
</style>
