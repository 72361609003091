import BaseModel from "./BaseModel";
// Study, ConditionStudy, Partner, PartnerCondition, , BlogpostCondition
import { Survey, Blogpost } from "../models";

export default class Condition extends BaseModel {
    static entity = "conditions";

    static access = {
        create: "admin",
        read: "public",
        update: "admin",
        delete: "admin",
    };

    // only fetched every day
    static cacheTimeout = 86400000;

    static fields() {
        return {
            // attributes
            id: this.attr(null),
            created_at: this.attr(""),
            updated_at: this.attr(""),
            name: this.attr(""),
            short_name: this.attr(""),
            key: this.attr(""),
            has_page: this.attr(""),
            person_image: this.attr(""),
            person_image_thumb: this.attr(""),
            social_share_image: this.attr(""),
            hero_bg_colour: this.attr(""),
            prevalence: this.attr(""),
            prevalence_notes_links: this.attr(""),
            therapeutic_area: this.attr(""),
            symptoms: this.attr(""),
            impacts: this.attr(""),
            genetic_evidence: this.attr(""),
            resources: this.attr(""),
            related_blogs: this.attr(""),
            description: this.attr(""),
            survey_id: this.attr(""),
            review: this.attr(""),
            // m-1 relationships
            survey: this.belongsTo(Survey, "survey_id"),
            // m-m relationships
            // studies: this.belongsToMany(Study, ConditionStudy, "condition_id", "study_id"),
            // blogposts: this.belongsToMany(Blogpost, BlogpostCondition, "condition_id", "blogpost_id"),
            // partners: this.belongsToMany(Partner, PartnerCondition, "condition_id", "partner_id"),
        };
    }

    // Example of overwriting the base class read
    static async $read({ by = [], field = null, prefix = null, cacheTimeout = this.cacheTimeout, vuexOrmAxiosOptions = {} } = {}) {
        if (Array.isArray(by) && by.length === 0) {
            // fetching all conditions
            const timeout = process.env.NUXT_APP_CLIENT_TYPE === "admin" ? 0 : cacheTimeout;
            const values = Array.isArray(by) ? by : [].concat(by);
            if (this.fetchAgain(timeout)) {
                if (values.length === 0) this.fetching = true;
                const { response } = await this.api().get("/conditions");
                if (values.length === 0) {
                    this.fetching = false;
                    this.setLastFetched();
                }
                return Array.isArray(by) ? response.data : response.data[0];
            } else {
                const items = this.query()
                    .where((item) => values.includes(item[field]))
                    .get();
                return Array.isArray(by) ? items : items[0];
            }
        } else {
            // fetching some or one condition/s
            return super.$read({ by, field, prefix, cacheTimeout, vuexOrmAxiosOptions });
        }
    }

    static read_condition_by_key(key) {
        return this.api().get(`/conditions/key/${key}`);
    }

    static read_conditions_by_keys(keys) {
        this.api().put("/conditions/keys", { keys });
    }

    // static read_conditions_for_study_by_key(study_key) {
    //     return this.api().get(`/conditions/study/key/${study_key}`);
    // }

    // static read_conditions_with_page() {
    //     return this.api().get("/conditions-with-page");
    // }
}
